.HowItWork {
  padding-top: 70px;
  padding-bottom: 120px;
  background-color: #fafcfe;
  background-image: url(/img/HowItWork-Bg.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  @include r(xs) {
    padding-top: 60px;
    padding-bottom: 107px; }
  &-Body {}
  &-Title {
    margin-bottom: 30px;
    text-align: center;
    &:before {
      width: 0; } }
  &-Subtitle {
    margin-bottom: 115px;
    font-size: 18px;
    text-align: center;
    color: #1768ad; }
  &-Content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    position: relative;
    @include r(sm) {
      flex-direction: column;
      align-items: center; }
    &:before {
      width: 100%;
      content: 'процесс';
      font-size: 180px;
      font-weight: 900;
      text-align: center;
      color: #f5f9fd;
      line-height: 0;
      letter-spacing: 18px;
      position: absolute;
      top: 50%;
      left: 0;
      @include r(sm) {
        width: 530px;
        top: auto;
        bottom: 200px;
        left: -230px;
        transform: rotate(-90deg); } } }
  &-Items {
    width: 33.33%;
    height: 143px;
    margin-bottom: 190px;
    padding-right: 5%;
    padding-top: 50px;
    padding-left: 25px;
    position: relative;
    counter-increment: num;
    &:last-child, &:nth-last-child(2) {
      margin: 0;
      &:before {
        color: #e9f1fb; } }
    @include r(lg) {
      height: 125px; }
    @include r(sm) {
      width: 250px;
      height: 144px;
      margin-bottom: 130px;
      padding-top: 0;
      padding-right: 0;
      padding-left: 21px;
      display: flex;
      align-items: center;
      &:nth-last-child(2) {
        margin-bottom: 130px; } }
    &:before {
      content: '0'counter(num);
      font-size: 100px;
      font-weight: 900;
      color: #ffffff;
      line-height: 0.85;
      position: absolute;
      top: -59px;
      left: 100px;
      @include r(sm) {
        font-size: 72px;
        font-weight: 900;
        top: -45px;
        left: 120px; } }
    &:nth-child(3n),
    &:last-child {
      .HowItWork-Items-Arrow {
        display: none; } }
    &:nth-child(3n) {
      .HowItWork-Items-Arrow {
        @include r(sm) {
          display: block; } } }
    &-Card {
      width: 217px;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      @include r(lg) {
        width: 180px; }
      @include r(sm) {
        width: 217px; }
      &:after {
        content: '';
        width: 100%;
        height: 100%;
        background-color: #d7e5f5;
        box-shadow: 0px 11px 21px 0 rgba(215, 229, 245, 0.5);
        border-radius: 5px;
        position: absolute;
        top: 0;
        left: 0; }
      &:before {
        content: '';
        width: 100%;
        height: 100%;
        border: solid 1px #00b4ff;
        border-radius: 5px;
        position: absolute;
        top: 19px;
        left: 23px; } }
    &-Arrow {
      position: absolute;
      top: 21px;
      left: calc(100% + 13px);
      @include r(sl) {
        width: 41%; }
      @include r(lg) {
        width: 30%; }
      @include r(sm) {
        width: 102px;
        transform: rotate(90deg);
        top: 185px;
        left: -20px; } }
    &-Circle {
      width: 81px;
      height: 81px;
      background-color: #ffffff;
      border-radius: 50%;
      box-shadow: 0px 11px 21px 0 rgba(215, 229, 245, 0.5);
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      right: -43px;
      bottom: -62px;
      z-index: 2; }
    &-CircleIcon {}
    &-Text {
      padding-left: 21px;
      font-size: 24px;
      font-weight: 900;
      color: #34457e;
      position: relative;
      z-index: 2;
      @include r(lg) {
        font-size: 18px; }
      @include r(sm) {
        padding-left: 16px;
        letter-spacing: -0.5px; }
      &:before {
        content: '';
        width: 1px;
        height: 75px;
        background-color: #ffffff;
        position: absolute;
        top: -10px;
        left: 0;
        @include r(sm) {
          top: calc(50% - 37px); } } } } }

