.NormatDocs {
  padding-top: 40px;
  padding-bottom: 55px;
  background-color: #fafcfe;
  @include r(xs) {
    padding-top: 20px;
    padding-bottom: 0; }
  &-Body {}
  &-Title {
    margin-bottom: 45px;
    @include r(xs) {
      margin-bottom: 20px; }
    &:before {
      left: 10em; } }
  &-Content {}
  &-Item {
    width: 100%;
    &-ImgWrap {
      margin-bottom: 10px;
      border: solid 8px rgba(234, 239, 243, 0.69);
      display: block; }
    &-Img {
      width: 100%;
      display: block; }
    &-Text {
      font-size: 14px;
      color: #1768ad;
      text-align: center;
      text-decoration: none;
      display: block;
      &:hover {
        text-decoration: underline; } } } }

.NormatDocs {
  &-Slider {
    .slick-slide {
      margin: 0 30px;
      @include r(lg) {
        margin: 0 15px; }
      @include r(sm) {
        margin: 0 5px; } } }
  &-Arrow {
    width: 50px;
    height: 50px;
    padding-top: 5px;
    background-color: #00b4ff;
    border: none;
    border-radius: 100%;
    position: absolute;
    top: calc(50% - 25px);
    z-index: 2; }
  &-Prev {
    left: 0;
    transform: rotate(-180deg); }
  &-Next {
    right: 0; } }
