.Map {
  width: 100%;
  height: 515px;
  position: relative;
  &-Overlay {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    display: none;
    @include r(xs) {
      display: flex;
      align-items: center;
      justify-content: center; }
    &-Text {
      padding: 2px 5px;
      background-color: #000;
      font-size: 18px;
      color: #ffffff; } } }
