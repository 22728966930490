.FormSection_theme_line {
  display: flex;
  flex-direction: row;
  .Input {
    width: 50%;
    margin: 0;
    border-radius: 5px 0 0 5px; }
  .Btn {
    width: 50%;
    height: 44px;
    margin: 0;
    border-radius: 0 5px 5px 0; }
  @include r(xs) {
    max-width: 260px;
    margin: 0 auto;
    flex-direction: column;
    .Input {
      width: 100%;
      margin-bottom: 10px;
      border-radius: 5px; }
    .Btn {
      width: 100%;
      height: 56px;
      border-radius: 5px; } } }
