.Header {
  width: 100%;
  padding: 15px 0 0;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  @include r(xs) {
    padding: 11px 0;
    background-color: #ffffff;
    position: fixed; }
  .Container {
    @include r(xs) {
      padding: 0; } }
  &-Body {
    display: flex;
    align-items: center;
    justify-content: space-between; }
  &-Logo {
    width: 150px;
    @include r(xs) {
      width: 108px; } }
  &-Line {
    width: 65%;
    height: 2px;
    margin: 0 50px;
    background-color: #ffffff;
    @include r(xs) {
      display: none; } }
  &-Info {} }


.Logo {
  &-Link {
    display: block; }
  &-Image {
    width: 100%;
    display: block; } }

.DefmenuBtn {
  display: none;
  @include r(xs) {
    display: flex; } }

.HeaderInfo {
  display: flex;
  flex-direction: column;
  &-Phone {} }

.HeaderPhone {
  color: #000;
  text-decoration: none;
  @include r(xs) {
    width: 49px;
    height: 45px;
    padding: 16px 14px;
    background: #1867ac;
    border-radius: 10px 0 0 10px;
    display: flex;
    align-items: center;
    justify-content: center; }
  &-Number {
    font-size: 24px;
    color: #146bb1;
    white-space: nowrap;
    @include r(xs) {
      display: none; } }
  &-Img {
    display: none;
    @include r(xs) {
      width: 26px;
      display: block; } }
  &-Text {
    font-size: 14px;
    white-space: nowrap;
    @include r(xs) {
      display: none; } } }


.HeaderLogin {
  font-size: 16px;
  font-weight: 300;
  color: $main-color;
  line-height: 1;
  @include r(xs) {
    display: none; } }

.DefmenuBtn {
  background-color: #1867ac; }
