.About {
  padding-top: 85px;
  padding-bottom: 200px;
  @include r(xs) {
    padding-top: 25px;
    padding-bottom: 230px; }
  &-Body {
    position: relative; }
  &-Title {
    margin-bottom: 90px;
    @include r(xs) {
      margin-bottom: 30px; }
    &:before {
      left: 6em; } }
  &-Content {
    max-width: 660px;
    padding-left: 66px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    @include r(xs) {
      padding-left: 15px; }
    &:before {
      content: 'О компании';
      font-size: 60px;
      font-weight: 900;
      color: #faf9f9;
      text-transform: uppercase;
      transform: rotate(-90deg);
      position: absolute;
      top: 312px;
      left: -190px;
      @include r(xs) {
        font-size: 36px;
        top: 170px;
        left: -120px; } } }
  &-Item {
    width: 40%;
    margin-bottom: 70px;
    position: relative;
    z-index: 2;
    @include r(xs) {
      width: 50%;
      order: 1; }
    &:nth-child(2) {
      width: 60%;
      @include r(xs) {
        width: 100%;
        order: 2; } }
    &:last-child {
      margin-bottom: 0; }
    &-Num {
      font-size: 72px;
      font-weight: 700;
      color: $main-color;
      @include r(sm) {
        font-size: 60px; }
      @include r(xs) {
        font-size: 48px; }
      sup {
        font-weight: 700; } }
    &-Text {
      font-size: 18px;
      color: #2a3c79;
      @include r(xs) {
        font-size: 14px; } } }
  &-Simg {
    max-width: 80%;
    position: absolute;
    right: -100px;
    bottom: -175px;
    @include r(xs) {
      width: 480px;
      max-width: none;
      right: auto;
      left: -15px; } } }
