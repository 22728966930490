.Contact {
  padding-top: 65px;
  padding-bottom: 80px;
  background-color: #e3f0ff;
  position: relative;
  z-index: 2;
  @include r(sm) {
    padding-bottom: 0; }
  @include r(xxs) {
    padding-top: 25px; }
  &-Body {}
  &-Title {
    margin-bottom: 40px;
    &:before {
      left: 13em;
      @include r(xs) {
        left: 8em; } }
    @include r(sm) {
      margin-bottom: 20px; } }
  &-Content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap; }
  &-Column {
    width: 50%;
    &_bd {
      padding-left: 45px;
      position: relative;
      &:before {
        content: '';
        width: 1px;
        height: 100%;
        background-color: #ffffff;
        position: absolute;
        top: 0;
        left: 0; }
      @include r(sm) {
        margin-top: 25px;
        padding-top: 25px; } }
    &_form {
      height: 0;
      @include r(sm) {
        height: auto; } }
    @include r(sm) {
      width: 100%;
      padding-left: 0;
      &:before {
        width: 100%;
        height: 1px;
        background-color: #ffffff;
        position: absolute;
        top: 0;
        left: 0; } } }
  &-Subtitle {
    font-size: 24px;
    font-weight: 300;
    color: #1e6db0;
    @include r(lg) {
      font-size: 22px; }
    @include r(xxs) {
      font-size: 15px; } }
  &-Info {
    margin-bottom: 15px;
    &:last-child {
      margin-bottom: 0; }
    &-Title {
      font-size: 18px;
      font-weight: 700;
      color: #2a3c79;
      @include r(xxs) {
        font-size: 16px; } }
    &-Text {
      font-size: 18px;
      color: #1e6db0;
      @include r(xxs) {
        font-size: 16px; }
      a {
        text-decoration: none;
        color: #1e6db0; } } } }

.ContactCard {
  max-width: 424px;
  padding: 120px 40px 60px;
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0px 11px 21px 0 rgba(30, 109, 176, 0.4);
  position: relative;
  @include r(sm) {
    top: 70px; }
  @include r(xxs) {
    padding: 110px 20px 34px; }
  &-SImg {
    position: absolute;
    top: -50px;
    left: 0;
    max-width: 100%;
    @include r(xxs) {
      max-width: 90%;
      top: -30px;
      left: 5%; } }
  &-Title {
    margin-bottom: 40px;
    font-size: 18px;
    color: #1e6db0;
    text-align: center;
    @include r(xxs) {
      margin-bottom: 15px;
      font-size: 14px; } }
  &-FormSection {
    max-width: 260px;
    margin: 0 auto; } }
