.ProcessingPersonalDataText {
  font-size: 14px;
  color: #4bc7fa;
  text-align: center;
  @include r(xs) {
    font-size: 13px; }
  &-Link {
    color: #d0dae6;
    text-decoration: underline;
    display: inline; } }
