.Intro {
  width: 100%;
  height: 100vh;
  min-height: 700px;
  padding-top: 140px;
  background-image: url(/img/Intro-Bg1.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  @include r(xs) {
    min-height: 650px;
    padding-top: 100px; }
  &-Title {
    margin-bottom: 15px;
    font-size: 36px;
    font-weight: 900;
    color: #2b3a78;
    @include r(xs) {
      margin-bottom: 25px;
      font-size: 24px;
      text-align: center; } }
  &-Subtitle {
    margin-bottom: 60px;
    font-size: 30px;
    color: #1669ae;
    @include r(xs) {
      margin-bottom: 20px;
      font-size: 18px;
      text-align: center; } }
  &-CardWrap {
    max-width: 500px;
    position: relative; }
  &-FormSection {}
  &-SImg {
    position: absolute;
    top: -20px;
    left: calc(100% - 100px);
    @include r(xs) {
      width: 400px;
      top: auto;
      left: 0;
      bottom: -140px; } } }

.IntroCard {
  padding: 25px 23px 45px;
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0px 21px 35px 0 rgba(40, 62, 125, 0.08);
  position: relative;
  z-index: 1;
  @include r(xs) {
    padding: 20px 15px 24px; }
  &-Title {
    margin-bottom: 25px;
    font-size: 18px;
    @include r(xs) {
      margin-bottom: 10px;
      font-size: 16px;
      text-align: center; } }
  &-FormSection {} }

.Intro_ver1 {
  .Intro {
    &-Body {
      max-width: 730px; } } }

.Intro_ver2 {
  background-image: url(/img/Intro-Bg2.jpg);
  .Intro {
    &-Title {
      margin-bottom: 20px;
      font-size: 41px;
      font-weight: 900;
      line-height: 1;
      color: #2b3a78;
      text-align: center;
      text-transform: uppercase;
      @include r(xs) {
        margin-bottom: 15px;
        font-size: 28px;
        text-align: center; }
      @include r(sm) {
        font-size: 35px; }
      .Intro-Title-Sub {
        font-size: 36px;
        font-weight: 700;
        color: #1768ad;
        text-transform: none;
        @include r(xs) {
          font-size: 24px; }
        @include r(sm) {
          font-size: 30px; } } }
    &-Subtitle {
      margin-bottom: 99px;
      font-size: 36px;
      color: #00b4ff;
      text-align: center;
      @include r(xs) {
        margin-bottom: 20px;
        font-size: 24px;
        text-align: center; } }
    &-CardWrap {
      max-width: 485px;
      margin: 0 auto;
      position: relative; }
    &-FormSection {}
    &-SImg {
      top: -60px;
      left: calc(50% - 480px);
      @include r(xs) {
        width: 322px;
        top: auto;
        left: calc(50% - 161px);
        bottom: -85px; } } }
  .IntroCard {
    padding: 40px 45px 50px;
    @include r(xs) {
      padding: 25px 10px 30px; }
    &-Title {
      margin-bottom: 39px;
      font-size: 18px;
      text-align: center;
      @include r(xs) {
        margin-bottom: 10px;
        font-size: 16px; } }
    &-FormSection {
      max-width: 260px;
      margin: 0 auto; } } }

.Intro_index_ver1 {
  height: auto;
  min-height: auto;
  padding-bottom: 150px;
  background-image: url(/img/Intro-Bg_index_ver1.jpg);
  overflow: visible;
  .Intro {
    &-Body {}
    &-Title {
      margin-bottom: 0;
      font-size: 48px;
      text-transform: uppercase;
      opacity: 1;
      @include r(xs) {
        margin-bottom: 5px;
        font-size: 30px;
        text-align: left; } }
    &-Subtitle {
      margin-bottom: 75px;
      font-size: 36px;
      font-weight: 900;
      position: relative;
      z-index: 2;
      @include r(xs) {
        margin-bottom: 60px;
        font-size: 24px;
        text-align: left; } }
    &-CardWrap {
      max-width: 570px;
      &:before {
        content: '';
        width: 1px;
        height: 40px;
        background-color: #307cbf;
        position: absolute;
        top: -62px;
        left: 0;
        @include r(xs) {
          top: -48px; } }
      &:after {
        content: '';
        width: 13px;
        height: 217px;
        background-image: url(/img/Intro-CardWrap_arrow.png);
        background-position: center bottom;
        position: absolute;
        top: calc(100% + 37px);
        left: 0;
        @include r(xs) {
          top: calc(100% + 5px); } } }
    &-SImg {
      top: auto;
      left: 0;
      bottom: -355px;
      @include r(sm) {
        left: -200px; }
      @include r(xs) {
        width: 700px;
        left: -250px;
        bottom: -250px; } } }
  .IntroCard {
    padding-left: 14px;
    padding-right: 14px;
    &-Title {}
    &-FormSection {} } }

.Intro_shumozaschitnye-ekrany_ver1 {
  height: auto;
  background-image: url(/img/Intro-Bg_shumozaschitnye-ekrany_ver1.jpg);
  @include r(xs) {
    min-height: 570px; }
  .Intro {
    &-Title {
      margin-bottom: 5px;
      text-align: center;
      text-transform: uppercase;
      @include r(xs) {
        text-align: left; } }
    &-Subtitle {
      margin-bottom: 35px;
      font-size: 36px;
      font-weight: 700;
      text-align: center;
      @include r(xs) {
        font-size: 17px;
        text-align: left; }
      br {
        @include r(sm) {
          display: none !important; } } }
    &-CardWrap {
      max-width: 485px;
      margin: 0 auto;
      position: relative; }
    &-SImg {
      &_shumozaschitnye-ekrany {
        &_ver1-1 {
          position: absolute;
          left: calc(50% - 483px);
          bottom: -280px;
          @include r(xs) {
            width: 800px;
            left: calc(50% - 450px);
            bottom: -220px; } }
        &_ver1-2 {
          position: absolute;
          right: calc(100% - 135px);
          bottom: -50px;
          @include r(xs) {
            width: 250px; } } } } }
  .IntroCard {
    padding: 40px 45px 50px;
    @include r(xs) {
      padding: 25px 10px 30px; }
    &-Title {
      margin-bottom: 39px;
      font-size: 18px;
      text-align: center;
      @include r(xs) {
        margin-bottom: 10px;
        font-size: 16px; } }
    &-FormSection {
      max-width: 260px;
      margin: 0 auto;
      position: relative;
      z-index: 2; } } }

.Intro_shumozaschitnye-ekrany_ver2 {
  height: auto;
  min-height: 630px;
  background-image: url(/img/Intro-Bg_shumozaschitnye-ekrany_ver2.jpg);
  overflow: visible;
  @include r(xs) {
    min-height: 570px; }
  .Container {
    position: relative; }
  .Intro {
    &-Title {
      max-width: 700px;
      position: relative;
      z-index: 2;
      @include r(xs) {
        margin-bottom: 10px;
        text-align: left; } }
    &-Subtitle {
      margin-bottom: 35px;
      position: relative;
      z-index: 2;
      @include r(xs) {
        margin-bottom: 15px;
        text-align: left; } }
    &-CardWrap {
      position: relative;
      z-index: 2; }
    &-SImg {
      &_shumozaschitnye-ekrany {
        &_ver2-1 {
          position: absolute;
          right: -200px;
          bottom: -440px;
          @include r(xs) {
            width: 450px;
            right: auto;
            left: -50px;
            bottom: -250px; } } } } }
  .IntroCard {
    &-Title {}
    &-FormSection {
      margin: 0; } } }

.Intro_proizvodstvo-metallokonstruktsij_ver1 {
  background-image: url(/img/Intro-Bg_shumozaschitnye-ekrany_ver2.jpg);
  .Container {
    position: relative; }
  .Intro {
    &-Title {
      margin-bottom: 5px;
      text-transform: uppercase;
      @include r(xs) {
        text-align: left; } }
    &-Subtitle {
      max-width: 650px;
      font-size: 36px;
      font-weight: 700;
      @include r(xs) {
        font-size: 24px;
        text-align: left; } }
    &-CardWrap {}
    &-SImg {
      &_proizvodstvo-metallokonstruktsij {
        &_ver1-1 {
          position: absolute;
          right: -30px;
          bottom: -650px;
          @include r(xs) {
            width: 650px;
            right: auto;
            left: -200px;
            bottom: -490px; } } } } }
  .IntroCard {
    &-Title {}
    &-FormSection {
      margin: 0; } } }

.Intro_perforatsiya-rulonnogo-metalla_ver1 {
  background-image: url(/img/Intro-Bg_shumozaschitnye-ekrany_ver2.jpg);
  .Container {
    position: relative; }
  .Intro {
    &-Title {
      margin-bottom: 5px;
      font-size: 40px;
      text-transform: uppercase;
      @include r(xs) {
        font-size: 24px;
        text-align: left; } }
    &-Subtitle {
      font-size: 36px;
      font-weight: 700;
      @include r(xs) {
        font-size: 18px;
        text-align: left; } }
    &-CardWrap {
      position: relative;
      z-index: 2; }
    &-SImg {
      &_perforatsiya-rulonnogo-metalla {
        &_ver1-1 {
          position: absolute;
          right: -30px;
          bottom: -700px;
          @include r(xs) {
            width: 650px;
            right: auto;
            left: -220px;
            bottom: -520px; } } } } }
  .IntroCard {
    &-Title {}
    &-FormSection {
      margin: 0; } } }

.Intro-SImg_besit-iconka {
  position: absolute;
  right: calc(100% - 105px);
  bottom: -575px;
  @include r(sm) {
    display: none; } }
