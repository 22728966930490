@charset "UTF-8";
/*
	HTML5 Reset :: style.css
	----------------------------------------------------------
	We have learned much from/been inspired by/taken code where offered from:

	Eric Meyer					:: https://meyerweb.com
	HTML5 Doctor				:: https://html5doctor.com
	and the HTML5 Boilerplate	:: https://html5boilerplate.com

-------------------------------------------------------------------------------*/
/* Let's default this puppy out
-------------------------------------------------------------------------------*/
html, body, body div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, abbr, address, cite, code, del, dfn, em, img, ins, kbd, q, samp, small, strong, sub, sup, var, b, i, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, figure, footer, header, menu, nav, section, time, mark, audio, video, details, summary {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font-weight: normal;
  vertical-align: baseline;
  background: transparent; }

article, aside, figure, footer, header, nav, section, details, summary {
  display: block; }

/* Handle box-sizing while better addressing child elements:
   https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/ */
html {
  box-sizing: border-box; }

*,
*:before,
*:after {
  box-sizing: inherit; }

/* consider resetting the default cursor: https://gist.github.com/murtaugh/5247154 */
/*!* Responsive images and other embedded objects
*!
img,
object,
embed {max-width: 100%;}*/
/*
   Note: keeping IMG here will cause problems if you're using foreground images as sprites.
	 In fact, it *will* cause problems with Google Maps' controls at small size.
	If this is the case for you, try uncommenting the following:

#map img {
		max-width: none;
}
*/
/* force a vertical scrollbar to prevent a jumpy page */
html {
  overflow-y: scroll; }

/* we use a lot of ULs that aren't bulleted.
	don't forget to restore the bullets within content. */
ul, ol {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none; }

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent; }

del {
  text-decoration: line-through; }

abbr[title], dfn[title] {
  border-bottom: 1px dotted #000;
  cursor: help; }

/* tables still need cellspacing="0" in the markup */
table {
  border-collapse: collapse;
  border-spacing: 0; }

th {
  font-weight: bold;
  vertical-align: bottom; }

td {
  font-weight: normal;
  vertical-align: top; }

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0; }

input, select {
  vertical-align: middle; }

pre {
  white-space: pre;
  /* CSS2 */
  white-space: pre-wrap;
  /* CSS 2.1 */
  white-space: pre-line;
  /* CSS 3 (and 2.1 as well, actually) */
  word-wrap: break-word;
  /* IE */ }

input[type="radio"] {
  vertical-align: text-bottom; }

input[type="checkbox"] {
  vertical-align: bottom; }

.ie7 input[type="checkbox"] {
  vertical-align: baseline; }

.ie6 input {
  vertical-align: text-bottom; }

select, input, textarea {
  font: 99% sans-serif; }

table {
  font-size: inherit;
  font: 100%; }

small {
  font-size: 85%; }

strong {
  font-weight: bold; }

td, td img {
  vertical-align: top; }

/* Make sure sup and sub don't mess with your line-heights https://gist.github.com/413930 */
sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

/* standardize any monospaced elements */
pre, code, kbd, samp {
  font-family: monospace, sans-serif; }

/* hand cursor on clickable elements */
.clickable,
label,
input[type=button],
input[type=submit],
input[type=file],
button {
  cursor: pointer; }

/* Webkit browsers add a 2px margin outside the chrome of form elements */
button, input, select, textarea {
  margin: 0; }

/* make buttons play nice in IE */
button,
input[type=button] {
  width: auto;
  overflow: visible; }

/* scale images in IE7 more attractively */
.ie7 img {
  -ms-interpolation-mode: bicubic; }

/* prevent BG image flicker upon hover
   (commented out as usage is rare, and the filter syntax messes with some pre-processors)
.ie6 html {filter: expression(document.execCommand("BackgroundImageCache", false, true));}
*/
/* let's clear some floats */
.clearfix:before, .clearfix:after {
  content: "\0020";
  display: block;
  height: 0;
  overflow: hidden; }

.clearfix:after {
  clear: both; }

.clearfix {
  zoom: 1; }

@font-face {
  font-family: "Circe";
  font-style: normal;
  font-weight: 100;
  src: url("/fonts/CirceLight/CirceLight.eot?#iefix") format("embedded-opentype"), url("/fonts/CirceLight/CirceLight.ttf") format("truetype"), url("/fonts/CirceLight/CirceLight.svg#Circe") format("svg"); }

@font-face {
  font-family: "Circe";
  font-style: normal;
  font-weight: 300;
  src: url("/fonts/Circe/Circe.eot?#iefix") format("embedded-opentype"), url("/fonts/Circe/Circe.ttf") format("truetype"), url("/fonts/Circe/Circe.svg#Circe") format("svg"); }

@font-face {
  font-family: "Circe";
  font-style: normal;
  font-weight: normal;
  src: url("/fonts/CirceMedium/CirceMedium.eot?#iefix") format("embedded-opentype"), url("/fonts/CirceMedium/CirceMedium.ttf") format("truetype"), url("/fonts/CirceMedium/CirceMedium.svg#Circe") format("svg"); }

@font-face {
  font-family: "Circe";
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/CircebBold/CircebBold.eot?#iefix") format("embedded-opentype"), url("/fonts/CircebBold/CircebBold.ttf") format("truetype"), url("/fonts/CircebBold/CircebBold.svg#Circe") format("svg"); }

@font-face {
  font-family: "Circe";
  font-style: normal;
  font-weight: 900;
  src: url("/fonts/CirceExtraBold/CirceExtraBold.eot?#iefix") format("embedded-opentype"), url("/fonts/CirceExtraBold/CirceExtraBold.ttf") format("truetype"), url("/fonts/CirceExtraBold/CirceExtraBold.svg#Circe") format("svg"); }

body {
  margin: 0;
  padding: 0;
  font-family: "Circe", sans-serif;
  font-weight: normal;
  line-height: 1.1; }

* {
  font-family: "Circe", sans-serif;
  outline: none; }

.Container {
  width: 100%;
  max-width: 1150px;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  box-sizing: border-box; }

sup {
  font-size: 60%; }

.Title {
  font-size: 36px;
  font-weight: 700;
  color: #2a3c79;
  position: relative;
  overflow: hidden; }
  @media (max-width: 575px) {
    .Title {
      font-size: 24px; } }
  .Title:before {
    content: '';
    width: 100%;
    border-bottom: 1px dashed #00b4ff;
    position: absolute;
    top: 22px;
    left: 15em; }
    @media (max-width: 575px) {
      .Title:before {
        top: 14px;
        left: 8em; } }
  .Title span {
    font-weight: 700; }

.Color_main {
  color: #00b4ff; }

.Color_accent {
  color: #2a3c79; }

.Phone-Only {
  display: none !important; }
  @media (max-width: 575px) {
    .Phone-Only {
      display: inherit !important; } }

.Phone-Hidden {
  display: inherit !important; }
  @media (max-width: 575px) {
    .Phone-Hidden {
      display: none !important; } }

.Btn {
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center; }

.Select {
  padding-left: 15px;
  background-color: #fff; }

.Select_theme_arrow {
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url(/img/SelectSrrow.png);
  background-position: calc(100% - 10px) center;
  background-repeat: no-repeat; }

.Input {
  padding-left: 15px;
  background-color: #fff;
  border: none;
  border-radius: 4px; }

.Input_error {
  background-color: #fed1dd !important; }

.ProcessingPersonalDataText {
  font-size: 14px;
  color: #4bc7fa;
  text-align: center; }
  @media (max-width: 575px) {
    .ProcessingPersonalDataText {
      font-size: 13px; } }
  .ProcessingPersonalDataText-Link {
    color: #d0dae6;
    text-decoration: underline;
    display: inline; }

.Btn {
  height: 44px;
  background-color: #00b4ff;
  font-size: 18px;
  font-weight: 300;
  color: #ffffff;
  border-radius: 5px;
  text-decoration: none; }

.Select {
  height: 44px;
  border: solid 1px #d4d4d4;
  border-radius: 5px; }

.Input {
  height: 44px;
  background-color: #ffffff;
  border: solid 1px #d4d4d4;
  border-radius: 5px; }

.Input-Wrap {
  position: relative; }
  .Input-Wrap:before {
    content: '';
    font-size: 16px;
    color: #aeadad;
    position: absolute;
    top: 12px;
    right: 10px; }
  .Input-Wrap_area:before {
    content: 'м²'; }
  .Input-Wrap_metre:before {
    content: 'м'; }

.File_theme_link {
  display: flex;
  justify-content: center;
  align-items: center; }
  .File_theme_link .File-Checkbox {
    width: 18px;
    height: 18px;
    margin-right: 13px;
    background-color: #ffffff;
    border: solid 1px #d4d4d4;
    border-radius: 3px;
    display: inline-block; }
  .File_theme_link .File-Link {
    font-size: 14px;
    color: #12223f; }
  .File_theme_link .File-Control {
    display: none; }

.File_active .File-Checkbox {
  background-image: url(/img/CheckboxIcon.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 75%; }

.FormSection {
  display: flex;
  flex-direction: column; }
  .FormSection-File {
    margin-bottom: 15px; }
  .FormSection .Input {
    width: 100%;
    margin-bottom: 10px; }
  .FormSection .Btn {
    width: 100%;
    height: 52px; }

.FormSection_theme_line {
  display: flex;
  flex-direction: row; }
  .FormSection_theme_line .Input {
    width: 50%;
    margin: 0;
    border-radius: 5px 0 0 5px; }
  .FormSection_theme_line .Btn {
    width: 50%;
    height: 44px;
    margin: 0;
    border-radius: 0 5px 5px 0; }
  @media (max-width: 575px) {
    .FormSection_theme_line {
      max-width: 260px;
      margin: 0 auto;
      flex-direction: column; }
      .FormSection_theme_line .Input {
        width: 100%;
        margin-bottom: 10px;
        border-radius: 5px; }
      .FormSection_theme_line .Btn {
        width: 100%;
        height: 56px;
        border-radius: 5px; } }

.FormSlider-Label {
  margin-bottom: 15px;
  margin-left: 15px;
  font-size: 16px;
  font-weight: 300;
  color: #00b4ff; }

.FormSlider .slick-arrow.slick-disabled {
  display: none; }

.FormSlider .Select, .FormSlider .Input {
  width: 100%; }

.FormSlider-Arrow {
  width: 260px;
  height: 52px;
  margin-top: 0 !important;
  position: relative;
  bottom: 34px; }
  @media (max-width: 575px) {
    .FormSlider-Arrow {
      width: 100%; } }
  .FormSlider-Arrow img {
    margin-left: 18px; }

.FormSlider .Btn {
  width: 260px;
  height: 52px;
  margin-top: 10px; }
  @media (max-width: 575px) {
    .FormSlider .Btn {
      width: 100%; } }

.Header {
  width: 100%;
  padding: 15px 0 0;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100; }
  @media (max-width: 575px) {
    .Header {
      padding: 11px 0;
      background-color: #ffffff;
      position: fixed; } }
  @media (max-width: 575px) {
    .Header .Container {
      padding: 0; } }
  .Header-Body {
    display: flex;
    align-items: center;
    justify-content: space-between; }
  .Header-Logo {
    width: 150px; }
    @media (max-width: 575px) {
      .Header-Logo {
        width: 108px; } }
  .Header-Line {
    width: 65%;
    height: 2px;
    margin: 0 50px;
    background-color: #ffffff; }
    @media (max-width: 575px) {
      .Header-Line {
        display: none; } }

.Logo-Link {
  display: block; }

.Logo-Image {
  width: 100%;
  display: block; }

.DefmenuBtn {
  display: none; }
  @media (max-width: 575px) {
    .DefmenuBtn {
      display: flex; } }

.HeaderInfo {
  display: flex;
  flex-direction: column; }

.HeaderPhone {
  color: #000;
  text-decoration: none; }
  @media (max-width: 575px) {
    .HeaderPhone {
      width: 49px;
      height: 45px;
      padding: 16px 14px;
      background: #1867ac;
      border-radius: 10px 0 0 10px;
      display: flex;
      align-items: center;
      justify-content: center; } }
  .HeaderPhone-Number {
    font-size: 24px;
    color: #146bb1;
    white-space: nowrap; }
    @media (max-width: 575px) {
      .HeaderPhone-Number {
        display: none; } }
  .HeaderPhone-Img {
    display: none; }
    @media (max-width: 575px) {
      .HeaderPhone-Img {
        width: 26px;
        display: block; } }
  .HeaderPhone-Text {
    font-size: 14px;
    white-space: nowrap; }
    @media (max-width: 575px) {
      .HeaderPhone-Text {
        display: none; } }

.HeaderLogin {
  font-size: 16px;
  font-weight: 300;
  color: #00b4ff;
  line-height: 1; }
  @media (max-width: 575px) {
    .HeaderLogin {
      display: none; } }

.DefmenuBtn {
  background-color: #1867ac; }

.WorkDirections {
  padding-top: 75px;
  padding-bottom: 70px; }
  .WorkDirections-Title {
    margin-bottom: 40px;
    padding-left: 35px;
    font-size: 36px;
    font-weight: 900;
    color: #1768ad;
    text-transform: uppercase; }
    @media (max-width: 575px) {
      .WorkDirections-Title {
        margin-bottom: 30px;
        padding-left: 0;
        font-size: 24px; } }
  .WorkDirections-Content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center; }
  .WorkDirections-Item {
    width: 50%;
    padding: 55px 35px 163px;
    background: linear-gradient(to right, #f0f5f7 0%, #f6f9fc 100%);
    overflow: hidden;
    position: relative; }
    @media (max-width: 575px) {
      .WorkDirections-Item {
        width: 100%;
        margin-bottom: 20px;
        height: 187px;
        padding: 40px 20px 0; } }
    .WorkDirections-Item:before {
      content: '';
      width: 100%;
      height: 100%;
      background-image: url(/img/WorkDirections-Item-Bg.png);
      background-position: right bottom;
      background-repeat: no-repeat;
      background-size: 217px 229px;
      position: absolute;
      top: 0;
      left: 0; }
    .WorkDirections-Item:after {
      content: '';
      width: 100%;
      height: 100%;
      background-position: center bottom;
      background-repeat: no-repeat;
      position: absolute;
      bottom: 0;
      left: 0; }
      @media (max-width: 575px) {
        .WorkDirections-Item:after {
          background-size: auto 40%; } }
    .WorkDirections-Item_bg_sandwich:after {
      background-image: url(/img/WorkDirections-Item-Bg-Spec1.png);
      background-position: left bottom; }
    .WorkDirections-Item_bg_shumozachita:after {
      background-image: url(/img/WorkDirections-Item-Bg-Spec2.png);
      background-size: 100% auto; }
    .WorkDirections-Item_bg_prodac-metallokonstr:after {
      background-image: url(/img/WorkDirections-Item-Bg-Spec3.png); }
    .WorkDirections-Item_bg_perforacia-rullonmetal:after {
      background-image: url(/img/WorkDirections-Item-Bg-Spec4.png);
      background-position: 98% bottom; }
    .WorkDirections-Item-Content {
      position: relative;
      z-index: 2; }
    .WorkDirections-Item-Title {
      margin-bottom: 20px;
      font-size: 18px;
      font-weight: 700;
      color: #555555;
      text-transform: uppercase; }
      @media (max-width: 575px) {
        .WorkDirections-Item-Title {
          font-size: 16px; } }
    .WorkDirections-Item-Btn {
      width: 155px;
      text-decoration: none; }

.WorkCycle {
  padding-bottom: 130px;
  position: relative; }
  @media (max-width: 767px) {
    .WorkCycle {
      padding-top: 50px; } }
  @media (max-width: 575px) {
    .WorkCycle {
      padding-top: 0;
      padding-bottom: 0; } }
  .WorkCycle-Title {
    margin-bottom: 15px; }
    @media (max-width: 575px) {
      .WorkCycle-Title {
        font-size: 23px;
        text-align: center; } }
    .WorkCycle-Title:before {
      left: 13em; }
  .WorkCycle-Subtitle {
    margin-bottom: 90px;
    font-size: 24px;
    color: #1768ad; }
    @media (max-width: 575px) {
      .WorkCycle-Subtitle {
        margin-bottom: 25px;
        font-size: 18px;
        text-align: center; } }
  .WorkCycle-Content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between; }
    @media (max-width: 575px) {
      .WorkCycle-Content {
        padding-top: 40px;
        padding-bottom: 40px;
        justify-content: center;
        position: relative; } }
  .WorkCycle-Item {
    width: calc(25% - 23px*3/4);
    height: 166px;
    padding-top: 100px;
    background-color: #ffffff;
    border-radius: 5px;
    box-shadow: 0px 11px 21px 0 rgba(43, 58, 120, 0.5);
    position: relative; }
    @media (max-width: 767px) {
      .WorkCycle-Item {
        width: calc(50% - 30px);
        margin-bottom: 75px; }
        .WorkCycle-Item:nth-of-type(2):before {
          width: 0; }
        .WorkCycle-Item:nth-of-type(3) {
          margin-bottom: 0; } }
    @media (max-width: 575px) {
      .WorkCycle-Item {
        width: calc(100% - 80px); }
        .WorkCycle-Item:nth-of-type(3) {
          margin-bottom: 75px; } }
    .WorkCycle-Item:before {
      content: '';
      width: 100%;
      border-bottom: 1px dashed #00b4ff;
      position: absolute;
      top: 25px;
      right: -50%;
      z-index: -1; }
      @media (max-width: 575px) {
        .WorkCycle-Item:before {
          width: 0;
          height: 100%;
          border: none;
          border-left: 1px dashed #00b4ff;
          top: 50%;
          right: 36px; } }
    .WorkCycle-Item:last-of-type {
      margin-bottom: 0; }
      .WorkCycle-Item:last-of-type:before {
        width: 0;
        height: 0; }
    .WorkCycle-Item-Progress {
      width: 100%;
      height: 3px;
      background-color: #d9d7d7;
      border-radius: 0 0 10px 10px;
      overflow: hidden;
      position: absolute;
      left: 0;
      bottom: 0; }
      .WorkCycle-Item-Progress:before {
        content: '';
        width: 0;
        height: 100%;
        background-color: #00b4ff;
        position: absolute;
        top: 0;
        left: 0; }
      .WorkCycle-Item-Progress_percent25:before {
        width: 25%; }
      .WorkCycle-Item-Progress_percent50:before {
        width: 50%; }
      .WorkCycle-Item-Progress_percent75:before {
        width: 75%; }
      .WorkCycle-Item-Progress_percent100:before {
        width: 100%; }
    .WorkCycle-Item-IconWrap {
      width: 134px;
      height: 134px;
      background-image: url(/img/WorkCycle-Item-IconWrap.png);
      background-repeat: no-repeat;
      background-size: 100% auto;
      position: absolute;
      top: -50px;
      left: calc(50% - 67px);
      display: flex;
      align-items: center;
      justify-content: center; }
    .WorkCycle-Item-Text {
      font-size: 18px;
      font-weight: 900;
      line-height: 1.2;
      color: #1768ad;
      text-align: center; }
  .WorkCycle-SBg {
    width: 100%;
    padding-top: 70px;
    background: linear-gradient(to right, #034da2 0%, #0094da 100%);
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: -1; }
    @media (max-width: 575px) {
      .WorkCycle-SBg {
        width: 969px;
        padding-top: 0;
        transform: rotate(-90deg);
        left: -465px;
        bottom: calc(50% - 55px); } }
    .WorkCycle-SBg-Text {
      font-size: 135px;
      font-weight: 700;
      text-align: center;
      color: #fff;
      opacity: 0.2;
      white-space: nowrap; }
      @media (max-width: 991px) {
        .WorkCycle-SBg-Text {
          font-size: 107px; } }
      @media (max-width: 767px) {
        .WorkCycle-SBg-Text {
          font-size: 78px; } }
      @media (max-width: 575px) {
        .WorkCycle-SBg-Text {
          font-size: 100px;
          letter-spacing: 4px; } }

.ProductType {
  padding-top: 40px;
  padding-bottom: 70px; }
  @media (max-width: 767px) {
    .ProductType {
      padding-bottom: 0; } }
  @media (max-width: 575px) {
    .ProductType {
      padding-bottom: 50px; } }
  .ProductType-Title {
    margin-bottom: 75px; }
    .ProductType-Title:before {
      left: 14em; }
  .ProductType-Content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center; }
  .ProductType-Item {
    width: 25%;
    padding: 0 20px;
    position: relative;
    border-right: 2px solid #e5e5e5; }
    @media (max-width: 767px) {
      .ProductType-Item {
        width: 50%;
        margin-bottom: 70px; } }
    @media (max-width: 575px) {
      .ProductType-Item {
        width: 100%;
        margin-bottom: 80px;
        border: none; } }
    .ProductType-Item:last-child {
      margin-bottom: 0;
      border: none; }
    .ProductType-Item-ImgWrap {
      width: 100%;
      height: 97px;
      margin-bottom: 35px;
      background-image: url(/img/ProductType-Item-ImgWrap.png);
      background-position: center bottom;
      background-repeat: no-repeat;
      background-size: auto 100%;
      position: relative;
      display: block; }
      @media (max-width: 575px) {
        .ProductType-Item-ImgWrap {
          margin-bottom: 25px; } }
    .ProductType-Item-Img {
      position: absolute;
      left: calc(50% - 40px);
      bottom: -11px; }
    .ProductType-Item-Text {
      margin-bottom: 15px;
      font-size: 18px;
      font-weight: 700;
      line-height: 1.2;
      color: #1768ad;
      text-align: center; }
    .ProductType-Item-Btn {
      height: 52px; }
      @media (max-width: 575px) {
        .ProductType-Item-Btn {
          width: 200px;
          margin: 0 auto; } }

.UsageArea {
  padding-top: 110px;
  padding-bottom: 55px;
  background-color: #f0f6fd; }
  @media (max-width: 575px) {
    .UsageArea {
      padding-top: 60px;
      padding-bottom: 25px; } }
  .UsageArea-Title {
    margin-bottom: 35px; }
    @media (max-width: 575px) {
      .UsageArea-Title {
        margin-bottom: 15px; } }
    .UsageArea-Title:before {
      left: 10em; }
  .UsageArea-Item {
    margin-bottom: 15px;
    padding-bottom: 30px;
    border-bottom: 1px dashed rgba(0, 180, 255, 0.2); }
    .UsageArea-Item:last-child {
      margin-bottom: 0;
      padding-bottom: 0;
      border: none; }
    .UsageArea-Item-Title {
      margin-bottom: 20px;
      padding-left: 18px;
      font-size: 24px;
      font-weight: 700;
      color: #1768ad;
      position: relative; }
      @media (max-width: 575px) {
        .UsageArea-Item-Title {
          padding-left: 14px;
          font-size: 16px; } }
      .UsageArea-Item-Title span {
        font-weight: inherit; }
      .UsageArea-Item-Title:before {
        content: '';
        width: 2px;
        height: calc(100% + 10px);
        background-color: #ff0000;
        position: absolute;
        top: -8px;
        left: 0; }
        @media (max-width: 575px) {
          .UsageArea-Item-Title:before {
            top: -6px; } }
    .UsageArea-Item-Content {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between; }
      @media (max-width: 575px) {
        .UsageArea-Item-Content {
          justify-content: center; } }
    .UsageArea-Item-ImgLink {
      width: calc(33.33% - 20px*2/3);
      border: 9px solid #fff;
      border-radius: 5px;
      display: block; }
      @media (max-width: 575px) {
        .UsageArea-Item-ImgLink {
          width: 100%;
          max-width: 320px;
          margin-bottom: 15px; } }
      .UsageArea-Item-ImgLink:last-child {
        margin-bottom: 0; }
    .UsageArea-Item-Img {
      width: 100%;
      display: block; }

.NormatDocs {
  padding-top: 40px;
  padding-bottom: 55px;
  background-color: #fafcfe; }
  @media (max-width: 575px) {
    .NormatDocs {
      padding-top: 20px;
      padding-bottom: 0; } }
  .NormatDocs-Title {
    margin-bottom: 45px; }
    @media (max-width: 575px) {
      .NormatDocs-Title {
        margin-bottom: 20px; } }
    .NormatDocs-Title:before {
      left: 10em; }
  .NormatDocs-Item {
    width: 100%; }
    .NormatDocs-Item-ImgWrap {
      margin-bottom: 10px;
      border: solid 8px rgba(234, 239, 243, 0.69);
      display: block; }
    .NormatDocs-Item-Img {
      width: 100%;
      display: block; }
    .NormatDocs-Item-Text {
      font-size: 14px;
      color: #1768ad;
      text-align: center;
      text-decoration: none;
      display: block; }
      .NormatDocs-Item-Text:hover {
        text-decoration: underline; }

.NormatDocs-Slider .slick-slide {
  margin: 0 30px; }
  @media (max-width: 991px) {
    .NormatDocs-Slider .slick-slide {
      margin: 0 15px; } }
  @media (max-width: 767px) {
    .NormatDocs-Slider .slick-slide {
      margin: 0 5px; } }

.NormatDocs-Arrow {
  width: 50px;
  height: 50px;
  padding-top: 5px;
  background-color: #00b4ff;
  border: none;
  border-radius: 100%;
  position: absolute;
  top: calc(50% - 25px);
  z-index: 2; }

.NormatDocs-Prev {
  left: 0;
  transform: rotate(-180deg); }

.NormatDocs-Next {
  right: 0; }

.StructureVer2 {
  background-color: #f0f6fd;
  position: relative;
  z-index: 2; }
  .StructureVer2-Card {
    background-image: linear-gradient(252deg, #0094da, #034da2);
    box-shadow: 0px 11px 21px 0 rgba(43, 58, 120, 0.5);
    border-radius: 5px;
    position: relative; }
    .StructureVer2-Card:before {
      content: 'Структура';
      font-size: 145px;
      font-weight: 700;
      color: #ffffff;
      transform: rotate(-90deg);
      position: absolute;
      left: -370px;
      bottom: 240px;
      z-index: -1; }
      @media (max-width: 575px) {
        .StructureVer2-Card:before {
          display: none; } }
    .StructureVer2-Card-Body {
      padding: 35px 40px 80px;
      position: relative;
      z-index: 2;
      overflow: hidden; }
      @media (max-width: 767px) {
        .StructureVer2-Card-Body {
          padding-bottom: 145px; } }
      @media (max-width: 575px) {
        .StructureVer2-Card-Body {
          padding: 15px 20px 145px 15px; } }
  .StructureVer2-Title {
    margin-bottom: 55px;
    color: #ffffff; }
    @media (max-width: 575px) {
      .StructureVer2-Title {
        margin-bottom: 20px; } }
    .StructureVer2-Title:before {
      border-color: #FFFFFF;
      left: 16em; }
      @media (max-width: 575px) {
        .StructureVer2-Title:before {
          left: 5em; } }
  .StructureVer2-Content {
    max-width: 470px;
    position: relative;
    z-index: 2; }
  .StructureVer2-Item {
    margin-bottom: 50px; }
    @media (max-width: 575px) {
      .StructureVer2-Item {
        margin-bottom: 25px; } }
    .StructureVer2-Item:last-child {
      margin-bottom: 0; }
    .StructureVer2-Item-Title {
      margin-bottom: 15px;
      padding-left: 30px;
      font-size: 24px;
      font-weight: 700;
      color: #ffffff;
      position: relative; }
      @media (max-width: 575px) {
        .StructureVer2-Item-Title {
          margin-bottom: 10px;
          padding-left: 18px;
          font-size: 16px; } }
      .StructureVer2-Item-Title:before {
        content: '';
        width: 6px;
        height: 6px;
        background-color: #ff0000;
        border-radius: 50%;
        position: absolute;
        top: 8px;
        left: 0; }
        @media (max-width: 575px) {
          .StructureVer2-Item-Title:before {
            top: 5px; } }
    .StructureVer2-Item-Text {
      padding-left: 30px;
      font-size: 16px;
      font-weight: 300;
      color: #ffffff; }
      @media (max-width: 575px) {
        .StructureVer2-Item-Text {
          padding-left: 18px;
          font-size: 14px; } }
  .StructureVer2-Simg {
    max-width: 50%;
    position: absolute;
    right: 0;
    bottom: 0; }
    @media (max-width: 767px) {
      .StructureVer2-Simg {
        max-width: 40%; } }
    @media (max-width: 575px) {
      .StructureVer2-Simg {
        width: 160px;
        max-width: none; } }

.Inovation {
  padding-top: 40px;
  padding-bottom: 65px; }
  @media (max-width: 575px) {
    .Inovation {
      padding-bottom: 30px; } }
  .Inovation-Body {
    position: relative; }
  .Inovation-Title {
    margin-bottom: 20px; }
    @media (max-width: 575px) {
      .Inovation-Title {
        margin-bottom: 15px; } }
    .Inovation-Title:before {
      left: 23em; }
  .Inovation-Subtitle {
    margin-bottom: 45px;
    font-size: 24px;
    color: #1768ad; }
    @media (max-width: 575px) {
      .Inovation-Subtitle {
        margin-bottom: 0;
        font-size: 16px; } }
    @media (max-width: 767px) {
      .Inovation-Subtitle br {
        display: none; } }
  .Inovation-Simg {
    position: absolute;
    right: 0;
    bottom: -150px; }
    @media (max-width: 991px) {
      .Inovation-Simg {
        width: 400px; } }
    @media (max-width: 575px) {
      .Inovation-Simg {
        width: 391px;
        position: relative;
        left: -44px;
        bottom: 20px; } }
  .Inovation-Content {
    max-width: 490px; }
  .Inovation-Item {
    margin-bottom: 30px;
    padding-left: 45px;
    font-size: 18px;
    font-weight: 300;
    color: #7a7a7a;
    text-shadow: 0 0 10px #fff, 0 0 10px #fff, 0 0 10px #fff, 0 0 10px #fff, 0 0 10px #fff, 0 0 10px #fff, 0 0 10px #fff, 0 0 10px #fff, 0 0 10px #fff, 0 0 10px #fff, 0 0 10px #fff, 0 0 10px #fff, 0 0 10px #fff, 0 0 10px #fff;
    position: relative; }
    @media (max-width: 575px) {
      .Inovation-Item {
        padding-left: 35px;
        font-size: 14px; } }
    .Inovation-Item:last-child {
      margin-bottom: 0; }
    .Inovation-Item-Icon {
      width: 22px;
      height: 22px;
      background-image: linear-gradient(to left, #0094da, #034da2);
      border-radius: 50%;
      position: absolute;
      top: -2px;
      left: 0; }
      .Inovation-Item-Icon:before {
        content: '';
        width: 100%;
        height: 100%;
        background-image: url(/img/Check_white.png);
        background-position: center center;
        background-repeat: no-repeat;
        position: absolute;
        top: 0;
        left: 0; }

.Intro {
  width: 100%;
  height: 100vh;
  min-height: 700px;
  padding-top: 140px;
  background-image: url(/img/Intro-Bg1.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden; }
  @media (max-width: 575px) {
    .Intro {
      min-height: 650px;
      padding-top: 100px; } }
  .Intro-Title {
    margin-bottom: 15px;
    font-size: 36px;
    font-weight: 900;
    color: #2b3a78; }
    @media (max-width: 575px) {
      .Intro-Title {
        margin-bottom: 25px;
        font-size: 24px;
        text-align: center; } }
  .Intro-Subtitle {
    margin-bottom: 60px;
    font-size: 30px;
    color: #1669ae; }
    @media (max-width: 575px) {
      .Intro-Subtitle {
        margin-bottom: 20px;
        font-size: 18px;
        text-align: center; } }
  .Intro-CardWrap {
    max-width: 500px;
    position: relative; }
  .Intro-SImg {
    position: absolute;
    top: -20px;
    left: calc(100% - 100px); }
    @media (max-width: 575px) {
      .Intro-SImg {
        width: 400px;
        top: auto;
        left: 0;
        bottom: -140px; } }

.IntroCard {
  padding: 25px 23px 45px;
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0px 21px 35px 0 rgba(40, 62, 125, 0.08);
  position: relative;
  z-index: 1; }
  @media (max-width: 575px) {
    .IntroCard {
      padding: 20px 15px 24px; } }
  .IntroCard-Title {
    margin-bottom: 25px;
    font-size: 18px; }
    @media (max-width: 575px) {
      .IntroCard-Title {
        margin-bottom: 10px;
        font-size: 16px;
        text-align: center; } }

.Intro_ver1 .Intro-Body {
  max-width: 730px; }

.Intro_ver2 {
  background-image: url(/img/Intro-Bg2.jpg); }
  .Intro_ver2 .Intro-Title {
    margin-bottom: 20px;
    font-size: 41px;
    font-weight: 900;
    line-height: 1;
    color: #2b3a78;
    text-align: center;
    text-transform: uppercase; }
    @media (max-width: 575px) {
      .Intro_ver2 .Intro-Title {
        margin-bottom: 15px;
        font-size: 28px;
        text-align: center; } }
    @media (max-width: 767px) {
      .Intro_ver2 .Intro-Title {
        font-size: 35px; } }
    .Intro_ver2 .Intro-Title .Intro-Title-Sub {
      font-size: 36px;
      font-weight: 700;
      color: #1768ad;
      text-transform: none; }
      @media (max-width: 575px) {
        .Intro_ver2 .Intro-Title .Intro-Title-Sub {
          font-size: 24px; } }
      @media (max-width: 767px) {
        .Intro_ver2 .Intro-Title .Intro-Title-Sub {
          font-size: 30px; } }
  .Intro_ver2 .Intro-Subtitle {
    margin-bottom: 99px;
    font-size: 36px;
    color: #00b4ff;
    text-align: center; }
    @media (max-width: 575px) {
      .Intro_ver2 .Intro-Subtitle {
        margin-bottom: 20px;
        font-size: 24px;
        text-align: center; } }
  .Intro_ver2 .Intro-CardWrap {
    max-width: 485px;
    margin: 0 auto;
    position: relative; }
  .Intro_ver2 .Intro-SImg {
    top: -60px;
    left: calc(50% - 480px); }
    @media (max-width: 575px) {
      .Intro_ver2 .Intro-SImg {
        width: 322px;
        top: auto;
        left: calc(50% - 161px);
        bottom: -85px; } }
  .Intro_ver2 .IntroCard {
    padding: 40px 45px 50px; }
    @media (max-width: 575px) {
      .Intro_ver2 .IntroCard {
        padding: 25px 10px 30px; } }
    .Intro_ver2 .IntroCard-Title {
      margin-bottom: 39px;
      font-size: 18px;
      text-align: center; }
      @media (max-width: 575px) {
        .Intro_ver2 .IntroCard-Title {
          margin-bottom: 10px;
          font-size: 16px; } }
    .Intro_ver2 .IntroCard-FormSection {
      max-width: 260px;
      margin: 0 auto; }

.Intro_index_ver1 {
  height: auto;
  min-height: auto;
  padding-bottom: 150px;
  background-image: url(/img/Intro-Bg_index_ver1.jpg);
  overflow: visible; }
  .Intro_index_ver1 .Intro-Title {
    margin-bottom: 0;
    font-size: 48px;
    text-transform: uppercase;
    opacity: 1; }
    @media (max-width: 575px) {
      .Intro_index_ver1 .Intro-Title {
        margin-bottom: 5px;
        font-size: 30px;
        text-align: left; } }
  .Intro_index_ver1 .Intro-Subtitle {
    margin-bottom: 75px;
    font-size: 36px;
    font-weight: 900;
    position: relative;
    z-index: 2; }
    @media (max-width: 575px) {
      .Intro_index_ver1 .Intro-Subtitle {
        margin-bottom: 60px;
        font-size: 24px;
        text-align: left; } }
  .Intro_index_ver1 .Intro-CardWrap {
    max-width: 570px; }
    .Intro_index_ver1 .Intro-CardWrap:before {
      content: '';
      width: 1px;
      height: 40px;
      background-color: #307cbf;
      position: absolute;
      top: -62px;
      left: 0; }
      @media (max-width: 575px) {
        .Intro_index_ver1 .Intro-CardWrap:before {
          top: -48px; } }
    .Intro_index_ver1 .Intro-CardWrap:after {
      content: '';
      width: 13px;
      height: 217px;
      background-image: url(/img/Intro-CardWrap_arrow.png);
      background-position: center bottom;
      position: absolute;
      top: calc(100% + 37px);
      left: 0; }
      @media (max-width: 575px) {
        .Intro_index_ver1 .Intro-CardWrap:after {
          top: calc(100% + 5px); } }
  .Intro_index_ver1 .Intro-SImg {
    top: auto;
    left: 0;
    bottom: -355px; }
    @media (max-width: 767px) {
      .Intro_index_ver1 .Intro-SImg {
        left: -200px; } }
    @media (max-width: 575px) {
      .Intro_index_ver1 .Intro-SImg {
        width: 700px;
        left: -250px;
        bottom: -250px; } }
  .Intro_index_ver1 .IntroCard {
    padding-left: 14px;
    padding-right: 14px; }

.Intro_shumozaschitnye-ekrany_ver1 {
  height: auto;
  background-image: url(/img/Intro-Bg_shumozaschitnye-ekrany_ver1.jpg); }
  @media (max-width: 575px) {
    .Intro_shumozaschitnye-ekrany_ver1 {
      min-height: 570px; } }
  .Intro_shumozaschitnye-ekrany_ver1 .Intro-Title {
    margin-bottom: 5px;
    text-align: center;
    text-transform: uppercase; }
    @media (max-width: 575px) {
      .Intro_shumozaschitnye-ekrany_ver1 .Intro-Title {
        text-align: left; } }
  .Intro_shumozaschitnye-ekrany_ver1 .Intro-Subtitle {
    margin-bottom: 35px;
    font-size: 36px;
    font-weight: 700;
    text-align: center; }
    @media (max-width: 575px) {
      .Intro_shumozaschitnye-ekrany_ver1 .Intro-Subtitle {
        font-size: 17px;
        text-align: left; } }
    @media (max-width: 767px) {
      .Intro_shumozaschitnye-ekrany_ver1 .Intro-Subtitle br {
        display: none !important; } }
  .Intro_shumozaschitnye-ekrany_ver1 .Intro-CardWrap {
    max-width: 485px;
    margin: 0 auto;
    position: relative; }
  .Intro_shumozaschitnye-ekrany_ver1 .Intro-SImg_shumozaschitnye-ekrany_ver1-1 {
    position: absolute;
    left: calc(50% - 483px);
    bottom: -280px; }
    @media (max-width: 575px) {
      .Intro_shumozaschitnye-ekrany_ver1 .Intro-SImg_shumozaschitnye-ekrany_ver1-1 {
        width: 800px;
        left: calc(50% - 450px);
        bottom: -220px; } }
  .Intro_shumozaschitnye-ekrany_ver1 .Intro-SImg_shumozaschitnye-ekrany_ver1-2 {
    position: absolute;
    right: calc(100% - 135px);
    bottom: -50px; }
    @media (max-width: 575px) {
      .Intro_shumozaschitnye-ekrany_ver1 .Intro-SImg_shumozaschitnye-ekrany_ver1-2 {
        width: 250px; } }
  .Intro_shumozaschitnye-ekrany_ver1 .IntroCard {
    padding: 40px 45px 50px; }
    @media (max-width: 575px) {
      .Intro_shumozaschitnye-ekrany_ver1 .IntroCard {
        padding: 25px 10px 30px; } }
    .Intro_shumozaschitnye-ekrany_ver1 .IntroCard-Title {
      margin-bottom: 39px;
      font-size: 18px;
      text-align: center; }
      @media (max-width: 575px) {
        .Intro_shumozaschitnye-ekrany_ver1 .IntroCard-Title {
          margin-bottom: 10px;
          font-size: 16px; } }
    .Intro_shumozaschitnye-ekrany_ver1 .IntroCard-FormSection {
      max-width: 260px;
      margin: 0 auto;
      position: relative;
      z-index: 2; }

.Intro_shumozaschitnye-ekrany_ver2 {
  height: auto;
  min-height: 630px;
  background-image: url(/img/Intro-Bg_shumozaschitnye-ekrany_ver2.jpg);
  overflow: visible; }
  @media (max-width: 575px) {
    .Intro_shumozaschitnye-ekrany_ver2 {
      min-height: 570px; } }
  .Intro_shumozaschitnye-ekrany_ver2 .Container {
    position: relative; }
  .Intro_shumozaschitnye-ekrany_ver2 .Intro-Title {
    max-width: 700px;
    position: relative;
    z-index: 2; }
    @media (max-width: 575px) {
      .Intro_shumozaschitnye-ekrany_ver2 .Intro-Title {
        margin-bottom: 10px;
        text-align: left; } }
  .Intro_shumozaschitnye-ekrany_ver2 .Intro-Subtitle {
    margin-bottom: 35px;
    position: relative;
    z-index: 2; }
    @media (max-width: 575px) {
      .Intro_shumozaschitnye-ekrany_ver2 .Intro-Subtitle {
        margin-bottom: 15px;
        text-align: left; } }
  .Intro_shumozaschitnye-ekrany_ver2 .Intro-CardWrap {
    position: relative;
    z-index: 2; }
  .Intro_shumozaschitnye-ekrany_ver2 .Intro-SImg_shumozaschitnye-ekrany_ver2-1 {
    position: absolute;
    right: -200px;
    bottom: -440px; }
    @media (max-width: 575px) {
      .Intro_shumozaschitnye-ekrany_ver2 .Intro-SImg_shumozaschitnye-ekrany_ver2-1 {
        width: 450px;
        right: auto;
        left: -50px;
        bottom: -250px; } }
  .Intro_shumozaschitnye-ekrany_ver2 .IntroCard-FormSection {
    margin: 0; }

.Intro_proizvodstvo-metallokonstruktsij_ver1 {
  background-image: url(/img/Intro-Bg_shumozaschitnye-ekrany_ver2.jpg); }
  .Intro_proizvodstvo-metallokonstruktsij_ver1 .Container {
    position: relative; }
  .Intro_proizvodstvo-metallokonstruktsij_ver1 .Intro-Title {
    margin-bottom: 5px;
    text-transform: uppercase; }
    @media (max-width: 575px) {
      .Intro_proizvodstvo-metallokonstruktsij_ver1 .Intro-Title {
        text-align: left; } }
  .Intro_proizvodstvo-metallokonstruktsij_ver1 .Intro-Subtitle {
    max-width: 650px;
    font-size: 36px;
    font-weight: 700; }
    @media (max-width: 575px) {
      .Intro_proizvodstvo-metallokonstruktsij_ver1 .Intro-Subtitle {
        font-size: 24px;
        text-align: left; } }
  .Intro_proizvodstvo-metallokonstruktsij_ver1 .Intro-SImg_proizvodstvo-metallokonstruktsij_ver1-1 {
    position: absolute;
    right: -30px;
    bottom: -650px; }
    @media (max-width: 575px) {
      .Intro_proizvodstvo-metallokonstruktsij_ver1 .Intro-SImg_proizvodstvo-metallokonstruktsij_ver1-1 {
        width: 650px;
        right: auto;
        left: -200px;
        bottom: -490px; } }
  .Intro_proizvodstvo-metallokonstruktsij_ver1 .IntroCard-FormSection {
    margin: 0; }

.Intro_perforatsiya-rulonnogo-metalla_ver1 {
  background-image: url(/img/Intro-Bg_shumozaschitnye-ekrany_ver2.jpg); }
  .Intro_perforatsiya-rulonnogo-metalla_ver1 .Container {
    position: relative; }
  .Intro_perforatsiya-rulonnogo-metalla_ver1 .Intro-Title {
    margin-bottom: 5px;
    font-size: 40px;
    text-transform: uppercase; }
    @media (max-width: 575px) {
      .Intro_perforatsiya-rulonnogo-metalla_ver1 .Intro-Title {
        font-size: 24px;
        text-align: left; } }
  .Intro_perforatsiya-rulonnogo-metalla_ver1 .Intro-Subtitle {
    font-size: 36px;
    font-weight: 700; }
    @media (max-width: 575px) {
      .Intro_perforatsiya-rulonnogo-metalla_ver1 .Intro-Subtitle {
        font-size: 18px;
        text-align: left; } }
  .Intro_perforatsiya-rulonnogo-metalla_ver1 .Intro-CardWrap {
    position: relative;
    z-index: 2; }
  .Intro_perforatsiya-rulonnogo-metalla_ver1 .Intro-SImg_perforatsiya-rulonnogo-metalla_ver1-1 {
    position: absolute;
    right: -30px;
    bottom: -700px; }
    @media (max-width: 575px) {
      .Intro_perforatsiya-rulonnogo-metalla_ver1 .Intro-SImg_perforatsiya-rulonnogo-metalla_ver1-1 {
        width: 650px;
        right: auto;
        left: -220px;
        bottom: -520px; } }
  .Intro_perforatsiya-rulonnogo-metalla_ver1 .IntroCard-FormSection {
    margin: 0; }

.Intro-SImg_besit-iconka {
  position: absolute;
  right: calc(100% - 105px);
  bottom: -575px; }
  @media (max-width: 767px) {
    .Intro-SImg_besit-iconka {
      display: none; } }

.WhyWe {
  padding-top: 60px;
  padding-bottom: 70px; }
  @media (max-width: 575px) {
    .WhyWe {
      padding-top: 20px;
      padding-bottom: 25px; } }
  .WhyWe-Title {
    margin-bottom: 15px; }
  .WhyWe-Subitle {
    max-width: 470px;
    margin-bottom: 55px;
    font-size: 24px;
    font-weight: 700;
    color: #1768ad; }
    @media (max-width: 575px) {
      .WhyWe-Subitle {
        margin-bottom: 25px;
        font-size: 16px; } }
    .WhyWe-Subitle span {
      font-weight: 700; }
  .WhyWe-Content {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    position: relative; }
    .WhyWe-Content:before {
      content: 'по лучшей цене';
      font-size: 150px;
      font-weight: 900;
      color: #faf9f9;
      letter-spacing: -7.5px;
      line-height: 0.35;
      text-align: center;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1; }
      @media (max-width: 991px) {
        .WhyWe-Content:before {
          font-size: 118px; } }
      @media (max-width: 767px) {
        .WhyWe-Content:before {
          font-size: 90px; } }
      @media (max-width: 575px) {
        .WhyWe-Content:before {
          width: 600px;
          font-size: 100px;
          top: 260px;
          left: -280px;
          transform: rotate(-90deg); } }
  .WhyWe-Items {
    width: calc(25% - 25px*3/4); }
    @media (max-width: 991px) {
      .WhyWe-Items {
        width: calc(25% - 10px*3/4); } }
    @media (max-width: 767px) {
      .WhyWe-Items {
        width: calc(50% - 20px*1/2);
        margin-bottom: 30px; } }
    @media (max-width: 575px) {
      .WhyWe-Items {
        width: 100%;
        padding-left: 45px; } }
    .WhyWe-Items:last-child {
      margin-bottom: 0; }
    .WhyWe-Items-Num {
      margin-bottom: 15px;
      font-size: 30px;
      font-weight: 100;
      color: #2a3c79;
      letter-spacing: -1.5px; }
      @media (max-width: 575px) {
        .WhyWe-Items-Num {
          margin-bottom: 10px;
          font-size: 24px; } }
    .WhyWe-Items-Title {
      margin-bottom: 20px;
      font-size: 17px;
      font-weight: 700;
      color: #1768ad; }
      @media (max-width: 575px) {
        .WhyWe-Items-Title {
          margin-bottom: 10px;
          font-size: 14px; } }
    .WhyWe-Items-Text {
      font-size: 15px;
      color: #7a7a7a; }
      @media (max-width: 575px) {
        .WhyWe-Items-Text {
          font-size: 14px; } }

.Calculator {
  background-image: url(/img/Calculator-Bg.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  z-index: 1; }
  .Calculator-BgImg {
    position: absolute;
    top: -45px;
    left: calc(50% - 750px); }
  .Calculator-Card {
    padding: 35px 49px 47px;
    background-color: #0a2e4c;
    background-image: url(/img/Calculator-Card-BgImg.png);
    background-position: right bottom;
    background-repeat: no-repeat;
    border-radius: 5px;
    box-shadow: 0px 11px 21px 0 rgba(10, 46, 76, 0.4);
    position: relative; }
    @media (max-width: 575px) {
      .Calculator-Card {
        background-image: url(/img/Calculator-Card-BgImg_mobile.png);
        padding: 15px 15px 120px; } }
    .Calculator-Card-Content {
      position: relative;
      z-index: 2; }
    .Calculator-Card-Title {
      margin-bottom: 20px; }
      @media (max-width: 575px) {
        .Calculator-Card-Title {
          margin-bottom: 15px; } }
      .Calculator-Card-Title:before {
        width: 0; }
    .Calculator-Card-Subtitle {
      margin-bottom: 40px;
      font-size: 18px;
      font-weight: 100;
      color: #fefefe; }
      @media (max-width: 767px) {
        .Calculator-Card-Subtitle {
          font-size: 16px; } }
      @media (max-width: 575px) {
        .Calculator-Card-Subtitle {
          margin-bottom: 35px;
          font-size: 14px; } }
    .Calculator-Card-Simg {
      position: absolute;
      right: -100px;
      bottom: -35px; }
      @media (max-width: 575px) {
        .Calculator-Card-Simg {
          width: 320px;
          bottom: -20px; } }
  .Calculator-FormSlider {
    max-width: 360px;
    max-height: 133px; }

.CalculatorForm-Row {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start; }

.CalculatorForm-Column {
  width: calc(50% - 90px*1/2);
  max-width: 365px;
  margin-right: 90px;
  margin-bottom: 20px; }
  @media (max-width: 767px) {
    .CalculatorForm-Column {
      width: calc(50% - 15px*1/2);
      margin-right: 15px; } }
  @media (max-width: 575px) {
    .CalculatorForm-Column {
      width: 100%;
      margin-right: 0; } }
  .CalculatorForm-Column:last-child {
    margin-right: 0; }

.CalculatorForm-Label {
  margin-bottom: 10px;
  margin-left: 17px;
  font-size: 16px;
  color: #00b4ff; }
  @media (max-width: 575px) {
    .CalculatorForm-Label {
      font-size: 14px; } }

.CalculatorForm-Btn {
  width: 100%;
  height: 66px;
  margin-top: 13px; }
  @media (max-width: 575px) {
    .CalculatorForm-Btn {
      height: 52px; } }

.CalculatorForm-ProcessingPersonalDataText {
  margin-top: 20px; }

.CalculatorForm .Select {
  width: 100%; }

.CalculatorForm .Input {
  width: 100%; }
  @media (max-width: 767px) {
    .CalculatorForm .Input {
      font-size: 13px; } }
  @media (max-width: 575px) {
    .CalculatorForm .Input {
      font-size: 14px; } }

.Calculator-Card_ver2 {
  padding-left: 20px;
  padding-right: 20px; }
  @media (max-width: 575px) {
    .Calculator-Card_ver2 {
      padding-left: 15px;
      padding-right: 15px;
      padding-bottom: 80px; } }

.Calculator-Card_theme_gradient {
  background-color: transparent;
  background-image: url(/img/Calculator-Card-BgImg_gradient.png); }
  .Calculator-Card_theme_gradient:before {
    content: '';
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, #034da2 0%, #0091d8 100%);
    border-radius: 5px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1; }

.Structure {
  padding-top: 80px;
  padding-bottom: 80px;
  background-color: #f0f6fd; }
  .Structure-Title {
    margin-bottom: 65px; }
    .Structure-Title:before {
      left: 13em; }
  .Structure-Content {
    display: flex;
    flex-direction: row; }
  .Structure-SImgWrap {
    width: 50%;
    position: relative; }
  .Structure-SImg {
    max-width: 100%;
    position: absolute;
    top: 0;
    left: -60px; }
  .Structure-Info {
    width: 50%; }
  .Structure-Item {
    margin-bottom: 50px;
    padding-left: 25px;
    position: relative; }
    .Structure-Item:before {
      content: '';
      width: 7px;
      height: 7px;
      background-color: #00b4ff;
      border-radius: 50%;
      position: absolute;
      top: 3px;
      left: 0; }
    .Structure-Item:after {
      content: '';
      width: 1px;
      height: 100%;
      background-color: #00b4ff;
      position: absolute;
      top: 3px;
      left: 3px; }
    .Structure-Item:last-child {
      margin-bottom: 0; }
    .Structure-Item-Title {
      margin-bottom: 15px;
      font-size: 16px;
      font-weight: 700;
      color: #1768ad; }
    .Structure-Item-Text {
      font-size: 14px;
      color: #7a7a7a; }

.HowItWork {
  padding-top: 70px;
  padding-bottom: 120px;
  background-color: #fafcfe;
  background-image: url(/img/HowItWork-Bg.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover; }
  @media (max-width: 575px) {
    .HowItWork {
      padding-top: 60px;
      padding-bottom: 107px; } }
  .HowItWork-Title {
    margin-bottom: 30px;
    text-align: center; }
    .HowItWork-Title:before {
      width: 0; }
  .HowItWork-Subtitle {
    margin-bottom: 115px;
    font-size: 18px;
    text-align: center;
    color: #1768ad; }
  .HowItWork-Content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    position: relative; }
    @media (max-width: 767px) {
      .HowItWork-Content {
        flex-direction: column;
        align-items: center; } }
    .HowItWork-Content:before {
      width: 100%;
      content: 'процесс';
      font-size: 180px;
      font-weight: 900;
      text-align: center;
      color: #f5f9fd;
      line-height: 0;
      letter-spacing: 18px;
      position: absolute;
      top: 50%;
      left: 0; }
      @media (max-width: 767px) {
        .HowItWork-Content:before {
          width: 530px;
          top: auto;
          bottom: 200px;
          left: -230px;
          transform: rotate(-90deg); } }
  .HowItWork-Items {
    width: 33.33%;
    height: 143px;
    margin-bottom: 190px;
    padding-right: 5%;
    padding-top: 50px;
    padding-left: 25px;
    position: relative;
    counter-increment: num; }
    .HowItWork-Items:last-child, .HowItWork-Items:nth-last-child(2) {
      margin: 0; }
      .HowItWork-Items:last-child:before, .HowItWork-Items:nth-last-child(2):before {
        color: #e9f1fb; }
    @media (max-width: 991px) {
      .HowItWork-Items {
        height: 125px; } }
    @media (max-width: 767px) {
      .HowItWork-Items {
        width: 250px;
        height: 144px;
        margin-bottom: 130px;
        padding-top: 0;
        padding-right: 0;
        padding-left: 21px;
        display: flex;
        align-items: center; }
        .HowItWork-Items:nth-last-child(2) {
          margin-bottom: 130px; } }
    .HowItWork-Items:before {
      content: "0" counter(num);
      font-size: 100px;
      font-weight: 900;
      color: #ffffff;
      line-height: 0.85;
      position: absolute;
      top: -59px;
      left: 100px; }
      @media (max-width: 767px) {
        .HowItWork-Items:before {
          font-size: 72px;
          font-weight: 900;
          top: -45px;
          left: 120px; } }
    .HowItWork-Items:nth-child(3n) .HowItWork-Items-Arrow, .HowItWork-Items:last-child .HowItWork-Items-Arrow {
      display: none; }
    @media (max-width: 767px) {
      .HowItWork-Items:nth-child(3n) .HowItWork-Items-Arrow {
        display: block; } }
    .HowItWork-Items-Card {
      width: 217px;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0; }
      @media (max-width: 991px) {
        .HowItWork-Items-Card {
          width: 180px; } }
      @media (max-width: 767px) {
        .HowItWork-Items-Card {
          width: 217px; } }
      .HowItWork-Items-Card:after {
        content: '';
        width: 100%;
        height: 100%;
        background-color: #d7e5f5;
        box-shadow: 0px 11px 21px 0 rgba(215, 229, 245, 0.5);
        border-radius: 5px;
        position: absolute;
        top: 0;
        left: 0; }
      .HowItWork-Items-Card:before {
        content: '';
        width: 100%;
        height: 100%;
        border: solid 1px #00b4ff;
        border-radius: 5px;
        position: absolute;
        top: 19px;
        left: 23px; }
    .HowItWork-Items-Arrow {
      position: absolute;
      top: 21px;
      left: calc(100% + 13px); }
      @media (max-width: 1200px) {
        .HowItWork-Items-Arrow {
          width: 41%; } }
      @media (max-width: 991px) {
        .HowItWork-Items-Arrow {
          width: 30%; } }
      @media (max-width: 767px) {
        .HowItWork-Items-Arrow {
          width: 102px;
          transform: rotate(90deg);
          top: 185px;
          left: -20px; } }
    .HowItWork-Items-Circle {
      width: 81px;
      height: 81px;
      background-color: #ffffff;
      border-radius: 50%;
      box-shadow: 0px 11px 21px 0 rgba(215, 229, 245, 0.5);
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      right: -43px;
      bottom: -62px;
      z-index: 2; }
    .HowItWork-Items-Text {
      padding-left: 21px;
      font-size: 24px;
      font-weight: 900;
      color: #34457e;
      position: relative;
      z-index: 2; }
      @media (max-width: 991px) {
        .HowItWork-Items-Text {
          font-size: 18px; } }
      @media (max-width: 767px) {
        .HowItWork-Items-Text {
          padding-left: 16px;
          letter-spacing: -0.5px; } }
      .HowItWork-Items-Text:before {
        content: '';
        width: 1px;
        height: 75px;
        background-color: #ffffff;
        position: absolute;
        top: -10px;
        left: 0; }
        @media (max-width: 767px) {
          .HowItWork-Items-Text:before {
            top: calc(50% - 37px); } }

.About {
  padding-top: 85px;
  padding-bottom: 200px; }
  @media (max-width: 575px) {
    .About {
      padding-top: 25px;
      padding-bottom: 230px; } }
  .About-Body {
    position: relative; }
  .About-Title {
    margin-bottom: 90px; }
    @media (max-width: 575px) {
      .About-Title {
        margin-bottom: 30px; } }
    .About-Title:before {
      left: 6em; }
  .About-Content {
    max-width: 660px;
    padding-left: 66px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap; }
    @media (max-width: 575px) {
      .About-Content {
        padding-left: 15px; } }
    .About-Content:before {
      content: 'О компании';
      font-size: 60px;
      font-weight: 900;
      color: #faf9f9;
      text-transform: uppercase;
      transform: rotate(-90deg);
      position: absolute;
      top: 312px;
      left: -190px; }
      @media (max-width: 575px) {
        .About-Content:before {
          font-size: 36px;
          top: 170px;
          left: -120px; } }
  .About-Item {
    width: 40%;
    margin-bottom: 70px;
    position: relative;
    z-index: 2; }
    @media (max-width: 575px) {
      .About-Item {
        width: 50%;
        order: 1; } }
    .About-Item:nth-child(2) {
      width: 60%; }
      @media (max-width: 575px) {
        .About-Item:nth-child(2) {
          width: 100%;
          order: 2; } }
    .About-Item:last-child {
      margin-bottom: 0; }
    .About-Item-Num {
      font-size: 72px;
      font-weight: 700;
      color: #00b4ff; }
      @media (max-width: 767px) {
        .About-Item-Num {
          font-size: 60px; } }
      @media (max-width: 575px) {
        .About-Item-Num {
          font-size: 48px; } }
      .About-Item-Num sup {
        font-weight: 700; }
    .About-Item-Text {
      font-size: 18px;
      color: #2a3c79; }
      @media (max-width: 575px) {
        .About-Item-Text {
          font-size: 14px; } }
  .About-Simg {
    max-width: 80%;
    position: absolute;
    right: -100px;
    bottom: -175px; }
    @media (max-width: 575px) {
      .About-Simg {
        width: 480px;
        max-width: none;
        right: auto;
        left: -15px; } }

.Contact {
  padding-top: 65px;
  padding-bottom: 80px;
  background-color: #e3f0ff;
  position: relative;
  z-index: 2; }
  @media (max-width: 767px) {
    .Contact {
      padding-bottom: 0; } }
  @media (max-width: 481px) {
    .Contact {
      padding-top: 25px; } }
  .Contact-Title {
    margin-bottom: 40px; }
    .Contact-Title:before {
      left: 13em; }
      @media (max-width: 575px) {
        .Contact-Title:before {
          left: 8em; } }
    @media (max-width: 767px) {
      .Contact-Title {
        margin-bottom: 20px; } }
  .Contact-Content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap; }
  .Contact-Column {
    width: 50%; }
    .Contact-Column_bd {
      padding-left: 45px;
      position: relative; }
      .Contact-Column_bd:before {
        content: '';
        width: 1px;
        height: 100%;
        background-color: #ffffff;
        position: absolute;
        top: 0;
        left: 0; }
      @media (max-width: 767px) {
        .Contact-Column_bd {
          margin-top: 25px;
          padding-top: 25px; } }
    .Contact-Column_form {
      height: 0; }
      @media (max-width: 767px) {
        .Contact-Column_form {
          height: auto; } }
    @media (max-width: 767px) {
      .Contact-Column {
        width: 100%;
        padding-left: 0; }
        .Contact-Column:before {
          width: 100%;
          height: 1px;
          background-color: #ffffff;
          position: absolute;
          top: 0;
          left: 0; } }
  .Contact-Subtitle {
    font-size: 24px;
    font-weight: 300;
    color: #1e6db0; }
    @media (max-width: 991px) {
      .Contact-Subtitle {
        font-size: 22px; } }
    @media (max-width: 481px) {
      .Contact-Subtitle {
        font-size: 15px; } }
  .Contact-Info {
    margin-bottom: 15px; }
    .Contact-Info:last-child {
      margin-bottom: 0; }
    .Contact-Info-Title {
      font-size: 18px;
      font-weight: 700;
      color: #2a3c79; }
      @media (max-width: 481px) {
        .Contact-Info-Title {
          font-size: 16px; } }
    .Contact-Info-Text {
      font-size: 18px;
      color: #1e6db0; }
      @media (max-width: 481px) {
        .Contact-Info-Text {
          font-size: 16px; } }
      .Contact-Info-Text a {
        text-decoration: none;
        color: #1e6db0; }

.ContactCard {
  max-width: 424px;
  padding: 120px 40px 60px;
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0px 11px 21px 0 rgba(30, 109, 176, 0.4);
  position: relative; }
  @media (max-width: 767px) {
    .ContactCard {
      top: 70px; } }
  @media (max-width: 481px) {
    .ContactCard {
      padding: 110px 20px 34px; } }
  .ContactCard-SImg {
    position: absolute;
    top: -50px;
    left: 0;
    max-width: 100%; }
    @media (max-width: 481px) {
      .ContactCard-SImg {
        max-width: 90%;
        top: -30px;
        left: 5%; } }
  .ContactCard-Title {
    margin-bottom: 40px;
    font-size: 18px;
    color: #1e6db0;
    text-align: center; }
    @media (max-width: 481px) {
      .ContactCard-Title {
        margin-bottom: 15px;
        font-size: 14px; } }
  .ContactCard-FormSection {
    max-width: 260px;
    margin: 0 auto; }

.Map {
  width: 100%;
  height: 515px;
  position: relative; }
  .Map-Overlay {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    display: none; }
    @media (max-width: 575px) {
      .Map-Overlay {
        display: flex;
        align-items: center;
        justify-content: center; } }
    .Map-Overlay-Text {
      padding: 2px 5px;
      background-color: #000;
      font-size: 18px;
      color: #ffffff; }

.ImPolitic {
  font-weight: 300; }
  .ImPolitic-Title {
    font-size: 20px;
    font-weight: bold; }

.ImCallback {
  max-width: 400px;
  padding: 30px 20px 20px; }
  @media (max-width: 575px) {
    .ImCallback {
      padding: 20px 10px; } }
  .ImCallback-Title {
    margin-bottom: 5px;
    font-size: 18px;
    font-weight: bold;
    color: #00b4ff;
    text-align: center;
    text-transform: uppercase; }
  .ImCallback-Subtitle {
    margin-bottom: 20px;
    font-size: 16px;
    color: #444444;
    text-align: center; }
  .ImCallback-Input {
    margin-bottom: 10px; }
  .ImCallback-Btn {
    width: 100%; }
    @media (max-width: 575px) {
      .ImCallback-Btn {
        padding-left: 10px;
        padding-right: 10px; } }

.ImThanks {
  display: none; }
  .ImThanks-Title {
    margin-bottom: 5px;
    font-size: 18px;
    font-weight: bold;
    color: #00b4ff;
    text-align: center;
    text-transform: uppercase; }
  .ImThanks-Subtitle {
    margin-bottom: 20px;
    font-size: 16px;
    color: #444444;
    text-align: center; }
  .ImThanks-Btn {
    width: 100%; }

.Footer {
  padding: 17px 0 16px;
  background-color: #333333; }
  .Footer-Body {
    display: flex;
    align-items: center;
    justify-content: space-between; }
    @media (max-width: 481px) {
      .Footer-Body {
        flex-direction: column; } }
  .Footer-Politic {
    font-size: 14px;
    color: #ffffff;
    letter-spacing: 0.5px; }
    @media (max-width: 481px) {
      .Footer-Politic {
        margin-bottom: 10px; } }
  .Footer-FijiLogo {
    width: 205px; }
    .Footer-FijiLogo img {
      max-width: 100%;
      height: auto;
      display: inline-block; }
