.FormSection {
  display: flex;
  flex-direction: column;
  &-File {
    margin-bottom: 15px; }
  .Input {
    width: 100%;
    margin-bottom: 10px; }
  .Btn {
    width: 100%;
    height: 52px; } }
