.Structure {
  padding-top: 80px;
  padding-bottom: 80px;
  background-color: #f0f6fd;
  &-Body {}
  &-Title {
    margin-bottom: 65px;
    &:before {
      left: 13em; } }
  &-Content {
    display: flex;
    flex-direction: row; }
  &-SImgWrap {
    width: 50%;
    position: relative; }
  &-SImg {
    max-width: 100%;
    position: absolute;
    top: 0;
    left: -60px; }
  &-Info {
    width: 50%; }
  &-Item {
    margin-bottom: 50px;
    padding-left: 25px;
    position: relative;
    &:before {
      content: '';
      width: 7px;
      height: 7px;
      background-color: $main-color;
      border-radius: 50%;
      position: absolute;
      top: 3px;
      left: 0; }
    &:after {
      content: '';
      width: 1px;
      height: 100%;
      background-color: $main-color;
      position: absolute;
      top: 3px;
      left: 3px; }
    &:last-child {
      margin-bottom: 0; }
    &-Title {
      margin-bottom: 15px;
      font-size: 16px;
      font-weight: 700;
      color: #1768ad; }
    &-Text {
      font-size: 14px;
      color: #7a7a7a; } } }
