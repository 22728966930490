.WorkDirections {
  padding-top: 75px;
  padding-bottom: 70px;
  &-Body {}
  &-Title {
    margin-bottom: 40px;
    padding-left: 35px;
    font-size: 36px;
    font-weight: 900;
    color: #1768ad;
    text-transform: uppercase;
    @include r(xs) {
      margin-bottom: 30px;
      padding-left: 0;
      font-size: 24px; } }
  &-Content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center; }
  &-Item {
    width: 50%;
    padding: 55px 35px 163px;
    background: linear-gradient(to right, #f0f5f7 0%,#f6f9fc 100%);
    overflow: hidden;
    position: relative;
    @include r(xs) {
      width: 100%;
      margin-bottom: 20px;
      height: 187px;
      padding: 40px 20px 0; }
    &:before {
      content: '';
      width: 100%;
      height: 100%;
      background-image: url(/img/WorkDirections-Item-Bg.png);
      background-position: right bottom;
      background-repeat: no-repeat;
      background-size: 217px 229px;
      position: absolute;
      top: 0;
      left: 0; }
    &:after {
      content: '';
      width: 100%;
      height: 100%;
      background-position: center bottom;
      background-repeat: no-repeat;
      position: absolute;
      bottom: 0;
      left: 0;
      @include r(xs) {
        background-size: auto 40%; } }
    &_bg {
      &_sandwich {
        &:after {
          background-image: url(/img/WorkDirections-Item-Bg-Spec1.png);
          background-position: left bottom; } }
      &_shumozachita {
        &:after {
          background-image: url(/img/WorkDirections-Item-Bg-Spec2.png);
          background-size: 100% auto; } }
      &_prodac-metallokonstr {
        &:after {
          background-image: url(/img/WorkDirections-Item-Bg-Spec3.png); } }
      &_perforacia-rullonmetal {
        &:after {
          background-image: url(/img/WorkDirections-Item-Bg-Spec4.png);
          background-position: 98% bottom; } } }
    &-Content {
      position: relative;
      z-index: 2; }
    &-Title {
      margin-bottom: 20px;
      font-size: 18px;
      font-weight: 700;
      color: #555555;
      text-transform: uppercase;
      @include r(xs) {
        font-size: 16px; } }
    &-Btn {
      width: 155px;
      text-decoration: none; } } }
