.Input {
  height: 44px;
  background-color: #ffffff;
  border: solid 1px #d4d4d4;
  border-radius: 5px; }

.Input-Wrap {
  position: relative;
  &:before {
    content: '';
    font-size: 16px;
    color: #aeadad;
    position: absolute;
    top: 12px;
    right: 10px; }
  &_area {
    &:before {
      content: 'м²'; } }
  &_metre {
    &:before {
      content: 'м'; } } }
