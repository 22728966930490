.Phone {
  &-Only {
    display: none !important;
    @include r(xs) {
      display: inherit !important; } }
  &-Hidden {
    display: inherit !important;
    @include r(xs) {
      display: none !important; } } }

//@import "../../common.blocks/Checkbox/_theme_switch/Checkbox_theme_switch.sass"

//@import "../../common.blocks/Select/Select.sass"
//@import "../../common.blocks/Select/_theme_arrow/Select_theme_arrow.sass"

@import "../../common.blocks/Btn/Btn.sass";

@import "../../common.blocks/Select/Select.sass";
@import "../../common.blocks/Select/_theme_arrow/Select_theme_arrow.sass";

@import "../../common.blocks/Input/Input.sass";
@import "../../common.blocks/Input/_error/Input_error.sass";

@import "../../common.blocks/ProcessingPersonalDataText/ProcessingPersonalDataText.sass";

@import "../../project.blocks/Btn/Btn.sass";

@import "../../project.blocks/Select/index.sass";

@import "../../project.blocks/Input/Input.sass";

@import "../../project.blocks/File/_theme_link/File_theme_link.sass";

@import "../../project.blocks/FormSection/FormSection.sass";
@import "../../project.blocks/FormSection/_theme_line/FormSection_theme_line.sass";

@import "../../project.blocks/FormSlider/index.sass";

