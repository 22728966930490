.StructureVer2 {
  background-color: #f0f6fd;
  position: relative;
  z-index: 2;
  &-Card {
    background-image: linear-gradient(252deg, #0094da, #034da2);
    box-shadow: 0px 11px 21px 0 rgba(43, 58, 120, 0.5);
    border-radius: 5px;
    position: relative;
    &:before {
      content: 'Структура';
      font-size: 145px;
      font-weight: 700;
      color: #ffffff;
      transform: rotate(-90deg);
      position: absolute;
      left: -370px;
      bottom: 240px;
      z-index: -1;
      @include r(xs) {
        display: none; } }
    &-Body {
      padding: 35px 40px 80px;
      position: relative;
      z-index: 2;
      overflow: hidden;
      @include r(sm) {
        padding-bottom: 145px; }
      @include r(xs) {
        padding: 15px 20px 145px 15px; } } }
  &-Title {
    margin-bottom: 55px;
    color: #ffffff;
    @include r(xs) {
      margin-bottom: 20px; }
    &:before {
      border-color: #FFFFFF;
      left: 16em;
      @include r(xs) {
        left: 5em; } } }
  &-Content {
    max-width: 470px;
    position: relative;
    z-index: 2; }
  &-Item {
    margin-bottom: 50px;
    @include r(xs) {
      margin-bottom: 25px; }
    &:last-child {
      margin-bottom: 0; }
    &-Title {
      margin-bottom: 15px;
      padding-left: 30px;
      font-size: 24px;
      font-weight: 700;
      color: #ffffff;
      position: relative;
      @include r(xs) {
        margin-bottom: 10px;
        padding-left: 18px;
        font-size: 16px; }
      &:before {
        content: '';
        width: 6px;
        height: 6px;
        background-color: #ff0000;
        border-radius: 50%;
        position: absolute;
        top: 8px;
        left: 0;
        @include r(xs) {
          top: 5px; } } }
    &-Text {
      padding-left: 30px;
      font-size: 16px;
      font-weight: 300;
      color: #ffffff;
      @include r(xs) {
        padding-left: 18px;
        font-size: 14px; } } }
  &-Simg {
    max-width: 50%;
    position: absolute;
    right: 0;
    bottom: 0;
    @include r(sm) {
      max-width: 40%; }
    @include r(xs) {
      width: 160px;
      max-width: none; } } }
