.ImCallback {
  max-width: 400px;
  padding: 30px 20px 20px;
  @include r(xs) {
    padding: 20px 10px; }
  &-Title {
    margin-bottom: 5px;
    font-size: 18px;
    font-weight: bold;
    color: $main-color;
    text-align: center;
    text-transform: uppercase; }
  &-Subtitle {
    margin-bottom: 20px;
    font-size: 16px;
    color: #444444;
    text-align: center; }
  &-Form {}
  &-Input {
    margin-bottom: 10px; }
  &-Btn {
    width: 100%;
    @include r(xs) {
      padding-left: 10px;
      padding-right: 10px; } } }
