.Calculator {
  background-image: url(/img/Calculator-Bg.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  z-index: 1;
  &-BgImg {
    position: absolute;
    top: -45px;
    left: calc(50% - 750px); }
  &-Body {}
  &-Card {
    padding: 35px 49px 47px;
    background-color: #0a2e4c;
    background-image: url(/img/Calculator-Card-BgImg.png);
    background-position: right bottom;
    background-repeat: no-repeat;
    border-radius: 5px;
    box-shadow: 0px 11px 21px 0 rgba(10, 46, 76, 0.4);
    position: relative;
    @include r(xs) {
      background-image: url(/img/Calculator-Card-BgImg_mobile.png);
      padding: 15px 15px 120px; }
    &-Content {
      position: relative;
      z-index: 2; }
    &-Title {
      margin-bottom: 20px;
      @include r(xs) {
        margin-bottom: 15px; }
      &:before {
        width: 0; } }
    &-Subtitle {
      margin-bottom: 40px;
      font-size: 18px;
      font-weight: 100;
      color: #fefefe;
      @include r(sm) {
        font-size: 16px; }
      @include r(xs) {
        margin-bottom: 35px;
        font-size: 14px; } }
    &-Simg {
      position: absolute;
      right: -100px;
      bottom: -35px;
      @include r(xs) {
        width: 320px;
        bottom: -20px; } } }
  &-FormSlider {
    max-width: 360px;
    max-height: 133px; } }

.CalculatorForm {
  &-Row {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start; }
  &-Column {
    width: calc(50% - 90px*1/2);
    max-width: 365px;
    margin-right: 90px;
    margin-bottom: 20px;
    @include r(sm) {
      width: calc(50% - 15px*1/2);
      margin-right: 15px; }
    @include r(xs) {
      width: 100%;
      margin-right: 0; }
    &:last-child {
      margin-right: 0; } }
  &-Label {
    margin-bottom: 10px;
    margin-left: 17px;
    font-size: 16px;
    color: #00b4ff;
    @include r(xs) {
      font-size: 14px; } }
  &-Btn {
    width: 100%;
    height: 66px;
    margin-top: 13px;
    @include r(xs) {
      height: 52px; } }
  &-ProcessingPersonalDataText {
    margin-top: 20px; }
  .Select {
    width: 100%; }
  .Input {
    width: 100%;
    @include r(sm) {
      font-size: 13px; }
    @include r(xs) {
      font-size: 14px; } } }

.Calculator-Card_ver2 {
  padding-left: 20px;
  padding-right: 20px;
  @include r(xs) {
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 80px; } }

.Calculator-Card_theme_gradient {
  background-color: transparent;
  background-image: url(/img/Calculator-Card-BgImg_gradient.png);
  &:before {
    content: '';
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, #034da2 0%,#0091d8 100%);
    border-radius: 5px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1; } }

