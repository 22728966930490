.ProductType {
  padding-top: 40px;
  padding-bottom: 70px;
  @include r(sm) {
    padding-bottom: 0; }
  @include r(xs) {
    padding-bottom: 50px; }
  &-Body {}
  &-Title {
    margin-bottom: 75px;
    &:before {
      left: 14em; } }
  &-Content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center; }
  &-Item {
    width: 25%;
    padding: 0 20px;
    position: relative;
    border-right: 2px solid #e5e5e5;
    @include r(sm) {
      width: 50%;
      margin-bottom: 70px; }
    @include r(xs) {
      width: 100%;
      margin-bottom: 80px;
      border: none; }
    &:last-child {
      margin-bottom: 0;
      border: none; }
    &-ImgWrap {
      width: 100%;
      height: 97px;
      margin-bottom: 35px;
      background-image: url(/img/ProductType-Item-ImgWrap.png);
      background-position: center bottom;
      background-repeat: no-repeat;
      background-size: auto 100%;
      position: relative;
      display: block;
      @include r(xs) {
        margin-bottom: 25px; } }
    &-Img {
      position: absolute;
      left: calc(50% - 40px);
      bottom: -11px; }
    &-Text {
      margin-bottom: 15px;
      font-size: 18px;
      font-weight: 700;
      line-height: 1.2;
      color: #1768ad;
      text-align: center; }
    &-Btn {
      height: 52px;
      @include r(xs) {
        width: 200px;
        margin: 0 auto; } } } }
