.Inovation {
  padding-top: 40px;
  padding-bottom: 65px;
  @include r(xs) {
    padding-bottom: 30px; }
  &-Body {
    position: relative; }
  &-Title {
    margin-bottom: 20px;
    @include r(xs) {
      margin-bottom: 15px; }
    &:before {
      left: 23em; } }
  &-Subtitle {
    margin-bottom: 45px;
    font-size: 24px;
    color: #1768ad;
    @include r(xs) {
      margin-bottom: 0;
      font-size: 16px; }
    br {
      @include r(sm) {
        display: none; } } }
  &-Simg {
    position: absolute;
    right: 0;
    bottom: -150px;
    @include r(lg) {
      width: 400px; }
    @include r(xs) {
      width: 391px;
      position: relative;
      left: -44px;
      bottom: 20px; } }
  &-Content {
    max-width: 490px; }
  &-Item {
    margin-bottom: 30px;
    padding-left: 45px;
    font-size: 18px;
    font-weight: 300;
    color: #7a7a7a;
    text-shadow: 0 0 10px #fff, 0 0 10px #fff, 0 0 10px #fff, 0 0 10px #fff, 0 0 10px #fff, 0 0 10px #fff, 0 0 10px #fff, 0 0 10px #fff, 0 0 10px #fff, 0 0 10px #fff, 0 0 10px #fff, 0 0 10px #fff, 0 0 10px #fff, 0 0 10px #fff;
    position: relative;
    @include r(xs) {
      padding-left: 35px;
      font-size: 14px; }
    &:last-child {
      margin-bottom: 0; }
    &-Icon {
      width: 22px;
      height: 22px;
      background-image: linear-gradient(to left, #0094da, #034da2);
      border-radius: 50%;
      position: absolute;
      top: -2px;
      left: 0;
      &:before {
        content: '';
        width: 100%;
        height: 100%;
        background-image: url(/img/Check_white.png);
        background-position: center center;
        background-repeat: no-repeat;
        position: absolute;
        top: 0;
        left: 0; } } } }
