.FormSlider {
  &-Label {
    margin-bottom: 15px;
    margin-left: 15px;
    font-size: 16px;
    font-weight: 300;
    color: $main-color; }
  .slick-arrow.slick-disabled {
    display: none; }
  .Select, .Input {
    width: 100%; }
  &-Arrow {
    width: 260px;
    height: 52px;
    margin-top: 0 !important;
    position: relative;
    bottom: 34px;
    @include r(xs) {
      width: 100%; }
    img {
      margin-left: 18px; } }
  .Btn {
    width: 260px;
    height: 52px;
    margin-top: 10px;
    @include r(xs) {
      width: 100%; } } }
