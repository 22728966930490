.WorkCycle {
  padding-bottom: 130px;
  position: relative;
  @include r(sm) {
    padding-top: 50px; }
  @include r(xs) {
    padding-top: 0;
    padding-bottom: 0; }
  .Container {}
  &-Body {}
  &-Title {
    margin-bottom: 15px;
    @include r(xs) {
      font-size: 23px;
      text-align: center; }
    &:before {
      left: 13em; } }
  &-Subtitle {
    margin-bottom: 90px;
    font-size: 24px;
    color: #1768ad;
    @include r(xs) {
      margin-bottom: 25px;
      font-size: 18px;
      text-align: center; } }
  &-Content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    @include r(xs) {
      padding-top: 40px;
      padding-bottom: 40px;
      justify-content: center;
      position: relative; } }
  &-Item {
    width: calc(25% - 23px*3/4);
    height: 166px;
    padding-top: 100px;
    background-color: #ffffff;
    border-radius: 5px;
    box-shadow: 0px 11px 21px 0 rgba(43, 58, 120, 0.5);
    position: relative;
    @include r(sm) {
      width: calc(50% - 30px);
      margin-bottom: 75px;
      &:nth-of-type(2) {
        &:before {
          width: 0; } }
      &:nth-of-type(3) {
        margin-bottom: 0; } }
    @include r(xs) {
      width: calc(100% - 80px);
      &:nth-of-type(3) {
        margin-bottom: 75px; } }
    &:before {
      content: '';
      width: 100%;
      border-bottom: 1px dashed #00b4ff;
      position: absolute;
      top: 25px;
      right: -50%;
      z-index: -1;
      @include r(xs) {
        width: 0;
        height: 100%;
        border: none;
        border-left: 1px dashed #00b4ff;
        top: 50%;
        right: 36px; } }
    &:last-of-type {
      margin-bottom: 0;
      &:before {
        width: 0;
        height: 0; } }
    &-Progress {
      width: 100%;
      height: 3px;
      background-color: #d9d7d7;
      border-radius: 0 0 10px 10px;
      overflow: hidden;
      position: absolute;
      left: 0;
      bottom: 0;
      &:before {
        content: '';
        width: 0;
        height: 100%;
        background-color: #00b4ff;
        position: absolute;
        top: 0;
        left: 0; }
      &_percent25 {
        &:before {
          width: 25%; } }
      &_percent50 {
        &:before {
          width: 50%; } }
      &_percent75 {
        &:before {
          width: 75%; } }
      &_percent100 {
        &:before {
          width: 100%; } } }
    &-IconWrap {
      width: 134px;
      height: 134px;
      background-image: url(/img/WorkCycle-Item-IconWrap.png);
      background-repeat: no-repeat;
      background-size: 100% auto;
      position: absolute;
      top: -50px;
      left: calc(50% - 67px);
      display: flex;
      align-items: center;
      justify-content: center; }
    &-Icon {}
    &-Text {
      font-size: 18px;
      font-weight: 900;
      line-height: 1.2;
      color: #1768ad;
      text-align: center; } }
  &-SBg {
    width: 100%;
    padding-top: 70px;
    background: linear-gradient(to right, #034da2 0%,#0094da 100%);
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: -1;
    @include r(xs) {
      width: 969px;
      padding-top: 0;
      transform: rotate(-90deg);
      left: -465px;
      bottom: calc(50% - 55px); }
    &-Text {
      font-size: 135px;
      font-weight: 700;
      text-align: center;
      color: #fff;
      opacity: 0.2;
      white-space: nowrap;
      @include r(lg) {
        font-size: 107px; }
      @include r(sm) {
        font-size: 78px; }
      @include r(xs) {
        font-size: 100px;
        letter-spacing: 4px; } } } }
