.UsageArea {
  padding-top: 110px;
  padding-bottom: 55px;
  background-color: #f0f6fd;
  @include r(xs) {
    padding-top: 60px;
    padding-bottom: 25px; }
  &-Body {}
  &-Title {
    margin-bottom: 35px;
    @include r(xs) {
      margin-bottom: 15px; }
    &:before {
      left: 10em; } }
  &-Content {}
  &-Item {
    margin-bottom: 15px;
    padding-bottom: 30px;
    border-bottom: 1px dashed rgba(0, 180, 255, 0.2);
    &:last-child {
      margin-bottom: 0;
      padding-bottom: 0;
      border: none; }
    &-Title {
      margin-bottom: 20px;
      padding-left: 18px;
      font-size: 24px;
      font-weight: 700;
      color: #1768ad;
      position: relative;
      @include r(xs) {
        padding-left: 14px;
        font-size: 16px; }
      span {
        font-weight: inherit; }
      &:before {
        content: '';
        width: 2px;
        height: calc(100% + 10px);
        background-color: #ff0000;
        position: absolute;
        top: -8px;
        left: 0;
        @include r(xs) {
          top: -6px; } } }
    &-Content {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      @include r(xs) {
        justify-content: center; } }
    &-ImgLink {
      width: calc(33.33% - 20px*2/3);
      border: 9px solid #fff;
      border-radius: 5px;
      display: block;
      @include r(xs) {
        width: 100%;
        max-width: 320px;
        margin-bottom: 15px; }
      &:last-child {
        margin-bottom: 0; } }
    &-Img {
      width: 100%;
      display: block; } } }
