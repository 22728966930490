.File_theme_link {
  display: flex;
  justify-content: center;
  align-items: center;
  .File-Checkbox {
    width: 18px;
    height: 18px;
    margin-right: 13px;
    background-color: #ffffff;
    border: solid 1px #d4d4d4;
    border-radius: 3px;
    display: inline-block; }
  .File-Link {
    font-size: 14px;
    color: #12223f; }
  .File-Control {
    display: none; } }

.File_active {
  .File-Checkbox {
    background-image: url(/img/CheckboxIcon.png);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 75%; } }
