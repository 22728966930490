.WhyWe {
  padding-top: 60px;
  padding-bottom: 70px;
  @include r(xs) {
    padding-top: 20px;
    padding-bottom: 25px; }
  &-Body {}
  &-Title {
    margin-bottom: 15px; }
  &-Subitle {
    max-width: 470px;
    margin-bottom: 55px;
    font-size: 24px;
    font-weight: 700;
    color: #1768ad;
    @include r(xs) {
      margin-bottom: 25px;
      font-size: 16px; }
    span {
      font-weight: 700; } }
  &-Content {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
    &:before {
      content: 'по лучшей цене';
      font-size: 150px;
      font-weight: 900;
      color: #faf9f9;
      letter-spacing: -7.5px;
      line-height: 0.35;
      text-align: center;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      @include r(lg) {
        font-size: 118px; }
      @include r(sm) {
        font-size: 90px; }
      @include r(xs) {
        width: 600px;
        font-size: 100px;
        top: 260px;
        left: -280px;
        transform: rotate(-90deg); } } }

  &-Items {
    width: calc(25% - 25px*3/4);
    @include r(lg) {
      width: calc(25% - 10px*3/4); }
    @include r(sm) {
      width: calc(50% - 20px*1/2);
      margin-bottom: 30px; }
    @include r(xs) {
      width: 100%;
      padding-left: 45px; }
    &:last-child {
      margin-bottom: 0; }
    &-Num {
      margin-bottom: 15px;
      font-size: 30px;
      font-weight: 100;
      color: $accent-color;
      letter-spacing: -1.5px;
      @include r(xs) {
        margin-bottom: 10px;
        font-size: 24px; } }
    &-Title {
      margin-bottom: 20px;
      font-size: 17px;
      font-weight: 700;
      color: #1768ad;
      @include r(xs) {
        margin-bottom: 10px;
        font-size: 14px; } }
    &-Text {
      font-size: 15px;
      color: #7a7a7a;
      @include r(xs) {
        font-size: 14px; } } } }
