//@import "../../../node_modules/bootstrap/scss/bootstrap-grid"
@import "bourbon";
@import "vars";
@import "mixins";

@include font-face("Circe", "/fonts/CirceLight/CirceLight", 100, $file-formats: eot ttf svg);
@include font-face("Circe", "/fonts/Circe/Circe", 300, $file-formats: eot ttf svg);
@include font-face("Circe", "/fonts/CirceMedium/CirceMedium", $file-formats: eot ttf svg);
@include font-face("Circe", "/fonts/CircebBold/CircebBold", 700, $file-formats: eot ttf svg);
@include font-face("Circe", "/fonts/CirceExtraBold/CirceExtraBold", 900, $file-formats: eot ttf svg);

body {
  margin: 0;
  padding: 0;
  font-family: $font-default;
  font-weight: normal;
  line-height: 1.1; }

* {
  font-family: $font-default;
  outline: none; }

.Container {
  width: 100%;
  max-width: 1150px;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  box-sizing: border-box; }

sup {
  font-size: 60%; }

.Title {
  font-size: 36px;
  font-weight: 700;
  color: $accent-color;
  position: relative;
  overflow: hidden;
  @include r(xs) {
    font-size: 24px; }
  &:before {
    content: '';
    width: 100%;
    border-bottom: 1px dashed $main-color;
    position: absolute;
    top: 22px;
    left: 15em;
    @include r(xs) {
      top: 14px;
      left: 8em; } }
  span {
    font-weight: 700; } }

.Color {
  &_main {
    color: $main-color; }
  &_accent {
    color: $accent-color; } }
